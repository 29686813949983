import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import {
    browserName,
    isDesktop,
    isMobile } from "react-device-detect";
import Modal from 'components/helpers/modal';
import * as Top from 'components/backgrounds/TopBackground';
import * as Bottom from 'components/backgrounds/BottomBackground';
import 'styles/modal.css'

const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        { threshold: 0.5 }
      );
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);
  
    return isIntersecting;
};

const MCAA = () => {

    function setBrowser(){
        localStorage.setItem('browser', `${browserName}`);
        localStorage.setItem('isMobile', `${isMobile}`);
        localStorage.setItem('isDesktop', `${isDesktop}`);
    }

    useEffect(() => {

        setBrowser();

    }, []);

    const boxRefs = [useRef(), useRef(), useRef(), useRef()];
    const isIntersecting = boxRefs.map(useOnScreen);

    useEffect(() => {
        boxRefs.forEach((ref, index) => {
          if (ref.current && isIntersecting[index]) {
            ref.current.classList.add("fade-in");
            ref.current.classList.add("bumping"); // Add bumping class
          }
        });
    }, [isIntersecting]);

    const [ skyColor, setSkyColor ] = useAtom(Top.skyColorAtom);
    const [ initialBGOpacity, setInitialBGOpacity ] = useAtom(Top.initialBGOpacityAtom);
    const [ animateBGOpacity, setAnimateBGOpacity ] = useAtom(Top.animateBGOpacityAtom);
    const [ initialStarsTop, setInitialStarsTop ] = useAtom(Top.initialStarsTopAtom);
    const [ animateStarsTop, setAnimateStarsTop ] = useAtom(Top.animateStarsTopAtom);
    const [ initialStarsLeft, setInitialStarsLeft ] = useAtom(Top.initialStarsLeftAtom);
    const [ animateStarsLeft, setAnimateStarsLeft ] = useAtom(Top.animateStarsLeftAtom);
    const [ initialStarsOpacity, setInitialStarsOpacity ] = useAtom(Top.initialStarsOpacityAtom);
    const [ animateStarsOpacity, setAnimateStarsOpacity ] = useAtom(Top.animateStarsOpacityAtom);
    const [ initialSunTop, setInitialSunTop ] = useAtom(Top.initialSunTopAtom);
    const [ animateSunTop, setAnimateSunTop ] = useAtom(Top.animateSunTopAtom);
    const [ initialSunLeft, setInitialSunLeft ] = useAtom(Top.initialSunLeftAtom);
    const [ animateSunLeft, setAnimateSunLeft ] = useAtom(Top.animateSunLeftAtom);

    const [ initialMainBottom, setInitialMainBottom ] = useAtom(Bottom.initialMainBottomAtom);
    const [ animateMainBottom, setAnimateMainBottom ] = useAtom(Bottom.animateMainBottomAtom);
    const [ initialMainLeft, setInitialMainLeft ] = useAtom(Bottom.initialMainLeftAtom);
    const [ animateMainLeft, setAnimateMainLeft ] = useAtom(Bottom.animateMainLeftAtom);
    const [ initialMainOpacity, setInitialMainOpacity ] = useAtom(Bottom.initialMainOpacityAtom);
    const [ animateMainOpacity, setAnimateMainOpacity ] = useAtom(Bottom.animateMainOpacityAtom);
    const [ initialMainScale, setInitialMainScale ] = useAtom(Bottom.initialMainScaleAtom);
    const [ animateMainScale, setAnimateMainScale ] = useAtom(Bottom.animateMainScaleAtom);
    const [ initialMintBottom, setInitialMintBottom ] = useAtom(Bottom.initialMintBottomAtom);
    const [ animateMintBottom, setAnimateMintBottom ] = useAtom(Bottom.animateMintBottomAtom);
    const [ initialMintLeft, setInitialMintLeft ] = useAtom(Bottom.initialMintLeftAtom);
    const [ animateMintLeft, setAnimateMintLeft ] = useAtom(Bottom.animateMintLeftAtom);
    const [ initialMintOpacity, setInitialMintOpacity ] = useAtom(Bottom.initialMintOpacityAtom);
    const [ animateMintOpacity, setAnimateMintOpacity ] = useAtom(Bottom.animateMintOpacityAtom);
    const [ initialMintScale, setInitialMintScale ] = useAtom(Bottom.initialMintScaleAtom);
    const [ animateMintScale, setAnimateMintScale ] = useAtom(Bottom.animateMintScaleAtom);
    const [ initialTimelineBottom, setInitialTimelineBottom ] = useAtom(Bottom.initialTimelineBottomAtom);
    const [ animateTimelineBottom, setAnimateTimelineBottom ] = useAtom(Bottom.animateTimelineBottomAtom);
    const [ initialTimelineLeft, setInitialTimelineLeft ] = useAtom(Bottom.initialTimelineLeftAtom);
    const [ animateTimelineLeft, setAnimateTimelineLeft ] = useAtom(Bottom.animateTimelineLeftAtom);
    const [ initialTimelineOpacity, setInitialTimelineOpacity ] = useAtom(Bottom.initialTimelineOpacityAtom);
    const [ animateTimelineOpacity, setAnimateTimelineOpacity ] = useAtom(Bottom.animateTimelineOpacityAtom);
    const [ initialTimelineScale, setInitialTimelineScale ] = useAtom(Bottom.initialTimelineScaleAtom);
    const [ animateTimelineScale, setAnimateTimelineScale ] = useAtom(Bottom.animateTimelineScaleAtom);
    const [ initialAboutBottom, setInitialAboutBottom ] = useAtom(Bottom.initialAboutBottomAtom);
    const [ animateAboutBottom, setAnimateAboutBottom ] = useAtom(Bottom.animateAboutBottomAtom);
    const [ initialAboutLeft, setInitialAboutLeft ] = useAtom(Bottom.initialAboutLeftAtom);
    const [ animateAboutLeft, setAnimateAboutLeft ] = useAtom(Bottom.animateAboutLeftAtom);
    const [ initialAboutOpacity, setInitialAboutOpacity ] = useAtom(Bottom.initialAboutOpacityAtom);
    const [ animateAboutOpacity, setAnimateAboutOpacity ] = useAtom(Bottom.animateAboutOpacityAtom);
    const [ initialAboutScale, setInitialAboutScale ] = useAtom(Bottom.initialAboutScaleAtom);
    const [ animateAboutScale, setAnimateAboutScale ] = useAtom(Bottom.animateAboutScaleAtom);
    const [ initialFoundersBottom, setInitialFoundersBottom ] = useAtom(Bottom.initialFoundersBottomAtom);
    const [ animateFoundersBottom, setAnimateFoundersBottom ] = useAtom(Bottom.animateFoundersBottomAtom);
    const [ initialFoundersLeft, setInitialFoundersLeft ] = useAtom(Bottom.initialFoundersLeftAtom);
    const [ animateFoundersLeft, setAnimateFoundersLeft ] = useAtom(Bottom.animateFoundersLeftAtom);
    const [ initialFoundersOpacity, setInitialFoundersOpacity ] = useAtom(Bottom.initialFoundersOpacityAtom);
    const [ animateFoundersOpacity, setAnimateFoundersOpacity ] = useAtom(Bottom.animateFoundersOpacityAtom);
    const [ initialFoundersScale, setInitialFoundersScale ] = useAtom(Bottom.initialFoundersScaleAtom);
    const [ animateFoundersScale, setAnimateFoundersScale ] = useAtom(Bottom.animateFoundersScaleAtom);
    const [ initialVisionBottom, setInitialVisionBottom ] = useAtom(Bottom.initialVisionBottomAtom);
    const [ animateVisionBottom, setAnimateVisionBottom ] = useAtom(Bottom.animateVisionBottomAtom);
    const [ initialVisionLeft, setInitialVisionLeft ] = useAtom(Bottom.initialVisionLeftAtom);
    const [ animateVisionLeft, setAnimateVisionLeft ] = useAtom(Bottom.animateVisionLeftAtom);
    const [ initialVisionOpacity, setInitialVisionOpacity ] = useAtom(Bottom.initialVisionOpacityAtom);
    const [ animateVisionOpacity, setAnimateVisionOpacity ] = useAtom(Bottom.animateVisionOpacityAtom);
    const [ initialVisionScale, setInitialVisionScale ] = useAtom(Bottom.initialVisionScaleAtom);
    const [ animateVisionScale, setAnimateVisionScale ] = useAtom(Bottom.animateVisionScaleAtom);
    const [ initialTokenomicsBottom, setInitialTokenomicsBottom ] = useAtom(Bottom.initialTokenomicsBottomAtom);
    const [ animateTokenomicsBottom, setAnimateTokenomicsBottom ] = useAtom(Bottom.animateTokenomicsBottomAtom);
    const [ initialTokenomicsLeft, setInitialTokenomicsLeft ] = useAtom(Bottom.initialTokenomicsLeftAtom);
    const [ animateTokenomicsLeft, setAnimateTokenomicsLeft ] = useAtom(Bottom.animateTokenomicsLeftAtom);
    const [ initialTokenomicsOpacity, setInitialTokenomicsOpacity ] = useAtom(Bottom.initialTokenomicsOpacityAtom);
    const [ animateTokenomicsOpacity, setAnimateTokenomicsOpacity ] = useAtom(Bottom.animateTokenomicsOpacityAtom);
    const [ initialTokenomicsScale, setInitialTokenomicsScale ] = useAtom(Bottom.initialTokenomicsScaleAtom);
    const [ animateTokenomicsScale, setAnimateTokenomicsScale ] = useAtom(Bottom.animateTokenomicsScaleAtom);

    const [ initialTitleTop, setInitialTitleTop ] = useAtom(Bottom.initialTitleTopAtom);
    const [ animateTitleTop, setAnimateTitleTop ] = useAtom(Bottom.animateTitleTopAtom);
    const [ initialTitleLeft, setInitialTitleLeft ] = useAtom(Bottom.initialTitleLeftAtom);
    const [ animateTitleLeft, setAnimateTitleLeft ] = useAtom(Bottom.animateTitleLeftAtom);
    const [ initialTitleOpacity, setInitialTitleOpacity ] = useAtom(Bottom.initialTitleOpacityAtom);
    const [ animateTitleOpacity, setAnimateTitleOpacity ] = useAtom(Bottom.animateTitleOpacityAtom);
    const [ initialTitleScale, setInitialTitleScale ] = useAtom(Bottom.initialTitleScaleAtom);
    const [ animateTitleScale, setAnimateTitleScale ] = useAtom(Bottom.animateTitleScaleAtom);



    useEffect(() => {
        setSkyColor('#288fe3');
        setInitialBGOpacity(animateBGOpacity)
        setAnimateBGOpacity(1)
    })

    useEffect(() => {
        setInitialStarsTop(animateStarsTop);
        setInitialStarsLeft(animateStarsLeft);
        setInitialStarsOpacity(animateStarsOpacity);
        setAnimateStarsTop('0px');
        setAnimateStarsLeft('0px');
        setAnimateStarsOpacity(0);
        setInitialSunTop(animateSunTop);
        setInitialSunLeft(animateSunLeft);
        setAnimateSunTop('15rem');
        setAnimateSunLeft('16rem');
    })

    useEffect(() => {
        setInitialTitleTop(animateTitleTop)
        setInitialTitleLeft(animateTitleLeft)
        setInitialTitleOpacity(animateTitleOpacity)
        setInitialTitleScale(animateTitleScale)

        setInitialMainBottom(animateMainBottom)
        setInitialMainLeft(animateMainLeft)
        setInitialMainOpacity(animateMainOpacity)
        setInitialMainScale(animateMainScale)
        setInitialMintBottom(animateMintBottom)
        setInitialMintLeft(animateMintLeft)
        setInitialMintOpacity(animateMintOpacity)
        setInitialMintScale(animateMintScale)
        setInitialTimelineBottom(animateTimelineBottom)
        setInitialTimelineLeft(animateTimelineLeft)
        setInitialTimelineOpacity(animateTimelineOpacity)
        setInitialTimelineScale(animateTimelineScale)
        setInitialAboutBottom(animateAboutBottom)
        setInitialAboutLeft(animateAboutLeft)
        setInitialAboutOpacity(animateAboutOpacity)
        setInitialAboutScale(animateAboutScale)
        setInitialFoundersBottom(animateFoundersBottom)
        setInitialFoundersLeft(animateFoundersLeft)
        setInitialFoundersOpacity(animateFoundersOpacity)
        setInitialFoundersScale(animateFoundersScale)
        setInitialVisionBottom(animateVisionBottom)
        setInitialVisionLeft(animateVisionLeft)
        setInitialVisionOpacity(animateVisionOpacity)
        setInitialVisionScale(animateVisionScale)
        setInitialTokenomicsBottom(animateTokenomicsBottom)
        setInitialTokenomicsLeft(animateTokenomicsLeft)
        setInitialTokenomicsOpacity(animateTokenomicsOpacity)
        setInitialTokenomicsScale(animateTokenomicsScale)

        setAnimateTitleTop('0vh')
        setAnimateTitleLeft('unset')
        setAnimateTitleOpacity(1)
        setAnimateTitleScale('scale(1)')

        setAnimateMainBottom('-0vh')
        setAnimateMainLeft('-0vw')
        setAnimateMainOpacity(1)
        setAnimateMainScale('scale(1)')
        setAnimateMintBottom('-0vh')
        setAnimateMintLeft('-0vw')
        setAnimateMintOpacity(0)
        setAnimateMintScale('scale(1)')
        setAnimateTimelineBottom('-0vh')
        setAnimateTimelineLeft('-0vw')
        setAnimateTimelineOpacity(0)
        setAnimateTimelineScale('scale(1)')
        setAnimateAboutBottom('-0vh')
        setAnimateAboutLeft('-0vw')
        setAnimateAboutOpacity(0)
        setAnimateAboutScale('scale(1)')
        setAnimateFoundersBottom('-0vh')
        setAnimateFoundersLeft('-0vw')
        setAnimateFoundersOpacity(0)
        setAnimateFoundersScale('scale(1)')
        setAnimateVisionBottom('-0vh')
        setAnimateVisionLeft('-0vw')
        setAnimateVisionOpacity(0)
        setAnimateVisionScale('scale(1)')
        setAnimateTokenomicsBottom('-0vh')
        setAnimateTokenomicsLeft('-0vw')
        setAnimateTokenomicsOpacity(0)
        setAnimateTokenomicsScale('scale(1)')
    })

    return (
        <div className='RouteWrapper HomeWrapper'>

            {/* ### SKY BACKGROUND ### */}

                <Top.TopBackground
                    skyColorAtom={skyColor}
                    initialBGOpacityAtom={initialBGOpacity}
                    animateBGOpacityAtom={animateBGOpacity}
                    initialStarsTopAtom={initialStarsTop}
                    animateStarsTopAtom={animateStarsTop}
                    initialStarsLeftAtom={initialStarsLeft}
                    animateStarsLeftAtom={animateStarsLeft}
                    initialStarsOpacityAtom={initialStarsOpacity}
                    animateStarsOpacityAtom={animateStarsOpacity}
                    initialSunTopAtom={initialSunTop}
                    animateSunTopAtom={animateSunTop}
                    initialSunLeftAtom={initialSunLeft}
                    animateSunLeftAtom={animateSunLeft}
                />

                <div className='Base Home'>
                    <Link className='link-to link-roadmap' to='/roadmap'>ROADMAP</Link>
                    <Link className='link-to link-about' to='/about'>ABOUT</Link>
                    <Link className='link-to link-founders' to='/founders'>FOUNDERS</Link>
                    <Link className='link-to link-vision' to='/vision'>VISION</Link>
                    <Link className='link-to link-tokenomics' to='/tokenomics'>TOKENOMICS</Link>

                    <div className='background map'></div>

                    <motion.div
                        className='map-container'
                        initial={{ opacity: 0, top: '150%' }}
                        animate={{ opacity: 1, top: '50%' }}
                        transition={{ type: 'tween', duration: 0.4 }}
                    >

                        <div>

                            <h3>Welcome to the MCAA's Buddha Boy NFT Club </h3>
                            <div className='flx gp10'>
                            {/*{isConnected ? (
                                <div><Link className='button' to='/mint'>Mint NFT</Link></div>
                            ) : !isConnected && (
                                <ConnectInline />
                            )}*/}
                            <div><a className='button' href='https://discord.gg/sMefg2cDF4' target='_blank' rel='noreferrer noopener' ref={boxRefs[0]}>Join Discord</a></div>
                            <div><Link className='button' to='/about' ref={boxRefs[1]}>About MCAA</Link></div>
                            </div>
                            <br />

                        </div>

                    </motion.div>

            {/* ##### VIEWPORT */}

                    <div className='viewport'>

                        <div className='siteMap flx'>
                            <div className='breadcrumbs'>
                                <Link className='' to='/home'>MCAA</Link>
                            </div>
                            <div className='next-bread'>
                                <Link className='' to='/about'>next: about</Link>
                            </div>
                        </div>

                    {/* ### CARD ### */}

                    {/* helpers */}

                        <Modal  className='p10'
                                activator={({ setShow }) => (

                            <button
                                className='help-button'
                                type='button'
                                onClick={() => setShow(true)}
                            >
                                Help?
                            </button>
                            )}
                        >

                            <h2 className='mb-5'><b>Help FAQ</b></h2>

                            <p className='border-b pb-4 text-base'>If you need help setting up a wallet for your browser on a computer please see <a href='https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en' target='_blank' rel='noreferrer noopener'>
                            <u>Coinbase Wallet</u>
                            </a> or <a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en' target='_blank' rel='noreferrer noopener'>
                            <u>Metamask</u>
                            </a>
                            </p>
                            <h3 className='font-bold text-lg mb-5'>Having issues?</h3>
                            <p className='text-base border-b pb-4'>We recommend using Google Chrome, Firefox or Brave Browser for better support. You can use <b>Metamask</b> or <b>Coinbase Wallet</b>, <b>Brave Browser</b> also has a built wallet supporting both Ethereum and Binance network.</p>
                            <h4 className='font-bold mb-2 text-lg'>Can't login</h4>
                            <p className='text-base'>- If nothing happens, check your wallet extension and unlock it then try again.</p>
                            <p className='border-b pb-4'></p>
                            <p className='border-b pb-4'><a href='https://metamask.io/buy-crypto/' target='_blank' rel='noreferrer noopener'>How to buy crypto currency in metamask using cards, banks of digital wallets tutorial</a></p>
                            <h3 className='font-bold text-lg mb-5'>Contact us</h3>
                            <p className='text-base'>Join the <a className='button' href='https://discord.gg/sMefg2cDF4' target='_blank' rel='noreferrer noopener'>MCAA Discord Server</a></p>

                        </Modal>

                    {/* ### BOTTOM BACKGROUND ### */}

                        <motion.div className="BG transition"
                            initial={{ zIndex: 4, left: '-100vw', opacity: 1, transform: 'scale(0.15)' }}
                            animate={{ zIndex: 3, left: '250vw', opacity: 1, transform: 'scale(1.35)' }}
                            transition={{ type: "tween", duration: 2 }}
                        >

                            <img src="/train.png" alt="Train" className="Train" priority="true" />

                        </motion.div>

                        <Bottom.BottomBackground
                            initialMainBottomAtom={initialMainBottom}
                            animateMainBottomAtom={animateMainBottom}
                            initialMainLeftAtom={initialMainLeft}
                            animateMainLeftAtom={animateMainLeft}
                            initialMainOpacityAtom={initialMainOpacity}
                            animateMainOpacityAtom={animateMainOpacity}
                            initialMainScaleAtom={initialMainScale}
                            animateMainScaleAtom={animateMainScale}
                            initialMintBottomAtom={initialMintBottom}
                            animateMintBottomAtom={animateMintBottom}
                            initialMintLeftAtom={initialMintLeft}
                            animateMintLeftAtom={animateMintLeft}
                            initialMintOpacityAtom={initialMintOpacity}
                            animateMintOpacityAtom={animateMintOpacity}
                            initialMintScaleAtom={initialMintScale}
                            animateMintScaleAtom={animateMintScale}
                            initialTimelineBottomAtom={initialTimelineBottom}
                            animateTimelineBottomAtom={animateTimelineBottom}
                            initialTimelineLeftAtom={initialTimelineLeft}
                            animateTimelineLeftAtom={animateTimelineLeft}
                            initialTimelineOpacityAtom={initialTimelineOpacity}
                            animateTimelineOpacityAtom={animateTimelineOpacity}
                            initialTimelineScaleAtom={initialTimelineScale}
                            animateTimelineScaleAtom={animateTimelineScale}
                            initialAboutBottomAtom={initialAboutBottom}
                            animateAboutBottomAtom={animateAboutBottom}
                            initialAboutLeftAtom={initialAboutLeft}
                            animateAboutLeftAtom={animateAboutLeft}
                            initialAboutOpacityAtom={initialAboutOpacity}
                            animateAboutOpacityAtom={animateAboutOpacity}
                            initialAboutScaleAtom={initialAboutScale}
                            animateAboutScaleAtom={animateAboutScale}
                            initialFoundersBottomAtom={initialFoundersBottom}
                            animateFoundersBottomAtom={animateFoundersBottom}
                            initialFoundersLeftAtom={initialFoundersLeft}
                            animateFoundersLeftAtom={animateFoundersLeft}
                            initialFoundersOpacityAtom={initialFoundersOpacity}
                            animateFoundersOpacityAtom={animateFoundersOpacity}
                            initialFoundersScaleAtom={initialFoundersScale}
                            animateFoundersScaleAtom={animateFoundersScale}
                            initialVisionBottomAtom={initialVisionBottom}
                            animateVisionBottomAtom={animateVisionBottom}
                            initialVisionLeftAtom={initialVisionLeft}
                            animateVisionLeftAtom={animateVisionLeft}
                            initialVisionOpacityAtom={initialVisionOpacity}
                            animateVisionOpacityAtom={animateVisionOpacity}
                            initialVisionScaleAtom={initialVisionScale}
                            animateVisionScaleAtom={animateVisionScale}
                            initialTokenomicsBottomAtom={initialTokenomicsBottom}
                            animateTokenomicsBottomAtom={animateTokenomicsBottom}
                            initialTokenomicsLeftAtom={initialTokenomicsLeft}
                            animateTokenomicsLeftAtom={animateTokenomicsLeft}
                            initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
                            animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
                            initialTokenomicsScaleAtom={initialTokenomicsScale}
                            animateTokenomicsScaleAtom={animateTokenomicsScale}
                            initialTitleTopAtom={initialTitleTop}
                            animateTitleTopAtom={animateTitleTop}
                            initialTitleLeftAtom={initialTitleLeft}
                            animateTitleLeftAtom={animateTitleLeft}
                            initialTitleOpacityAtom={initialTitleOpacity}
                            animateTitleOpacityAtom={animateTitleOpacity}
                            initialTitleScaleAtom={initialTitleScale}
                            animateTitleScaleAtom={animateTitleScale}
                        />

                    </div>

                </div>

        </div>
    );
}

export default MCAA