function getContractAddress(network) {
  switch (network.toLowerCase()) {
    case "mainnet":
      return "0xA3fC7eA538549101c420Df9a73Bc57d5fF7C8AF6";
    case "sepolia":
      return "0xC4ec0b04ECeBDBEB5110D928DE22E245A336c9d0";
    case "goerli":
      return "0xYourGoerliAddressHere";
    default:
      return "Network not supported";
  }
}

// const network = "sepolia";
const network = "mainnet";

export const contractAddress = getContractAddress(network);
