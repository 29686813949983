import { motion } from 'framer-motion';
import { atom } from "jotai";
import Saturn from "components/vectors/Saturn";

export const initialNeptuneTopAtom = atom("0rem")
export const animateNeptuneTopAtom = atom("0rem")
export const initialNeptuneLeftAtom = atom("0rem")
export const animateNeptuneLeftAtom = atom("0rem")
export const initialNeptuneOpacityAtom = atom("1")
export const animateNeptuneOpacityAtom = atom("1")
export const initialNeptuneScaleAtom = atom("scale(1)")
export const animateNeptuneScaleAtom = atom("scale(1)")
export const initialPlutoTopAtom = atom("0rem")
export const animatePlutoTopAtom = atom("0rem")
export const initialPlutoLeftAtom = atom("0rem")
export const animatePlutoLeftAtom = atom("0rem")
export const initialPlutoOpacityAtom = atom("1")
export const animatePlutoOpacityAtom = atom("1")
export const initialPlutoScaleAtom = atom("scale(1)")
export const animatePlutoScaleAtom = atom("scale(1)")
export const initialSandTopAtom = atom("0rem")
export const animateSandTopAtom = atom("0rem")
export const initialSandLeftAtom = atom("0rem")
export const animateSandLeftAtom = atom("0rem")
export const initialSandOpacityAtom = atom("1")
export const animateSandOpacityAtom = atom("1")
export const initialSandScaleAtom = atom("scale(1)")
export const animateSandScaleAtom = atom("scale(1)")
export const initialSaturnTopAtom = atom("0rem")
export const animateSaturnTopAtom = atom("0rem")
export const initialSaturnLeftAtom = atom("0rem")
export const animateSaturnLeftAtom = atom("0rem")
export const initialSaturnOpacityAtom = atom("1")
export const animateSaturnOpacityAtom = atom("1")
export const initialSaturnScaleAtom = atom("scale(1)")
export const animateSaturnScaleAtom = atom("scale(1)")
export const initialUranusTopAtom = atom("0rem")
export const animateUranusTopAtom = atom("0rem")
export const initialUranusLeftAtom = atom("0rem")
export const animateUranusLeftAtom = atom("0rem")
export const initialUranusOpacityAtom = atom("1")
export const animateUranusOpacityAtom = atom("1")
export const initialUranusScaleAtom = atom("scale(1)")
export const animateUranusScaleAtom = atom("scale(1)")

export const Planets = ({
    initialNeptuneTopAtom,
    animateNeptuneTopAtom,
    initialNeptuneLeftAtom,
    animateNeptuneLeftAtom,
    initialNeptuneOpacityAtom,
    animateNeptuneOpacityAtom,
    initialNeptuneScaleAtom,
    animateNeptuneScaleAtom,
    initialPlutoTopAtom,
    animatePlutoTopAtom,
    initialPlutoLeftAtom,
    animatePlutoLeftAtom,
    initialPlutoOpacityAtom,
    animatePlutoOpacityAtom,
    initialPlutoScaleAtom,
    animatePlutoScaleAtom,
    initialSandTopAtom,
    animateSandTopAtom,
    initialSandLeftAtom,
    animateSandLeftAtom,
    initialSandOpacityAtom,
    animateSandOpacityAtom,
    initialSandScaleAtom,
    animateSandScaleAtom,
    initialSaturnTopAtom,
    animateSaturnTopAtom,
    initialSaturnLeftAtom,
    animateSaturnLeftAtom,
    initialSaturnOpacityAtom,
    animateSaturnOpacityAtom,
    initialSaturnScaleAtom,
    animateSaturnScaleAtom,
    initialUranusTopAtom,
    animateUranusTopAtom,
    initialUranusLeftAtom,
    animateUranusLeftAtom,
    initialUranusOpacityAtom,
    animateUranusOpacityAtom,
    initialUranusScaleAtom,
    animateUranusScaleAtom
}) => {

    return (

        <div className="Planets">

            <motion.img initial={{ top: initialPlutoTopAtom, left: initialPlutoLeftAtom, opacity: initialPlutoOpacityAtom, transform: initialPlutoScaleAtom }}
                        animate={{ top: animatePlutoTopAtom, left: animatePlutoLeftAtom, opacity: animatePlutoOpacityAtom, transform: animatePlutoScaleAtom }}
                        transition={{ type: "tween", duration: 1 }}
                        src="/Pluto.png"
                        alt="Pluto"
                        width="300"
                        height="300"
            />

            <motion.img initial={{ top: initialSandTopAtom, left: initialSandLeftAtom, opacity: initialSandOpacityAtom, transform: initialSandScaleAtom }}
                        animate={{ top: animateSandTopAtom, left: animateSandLeftAtom, opacity: animateSandOpacityAtom, transform: animateSandScaleAtom }}
                        transition={{ type: "tween", duration: 1 }}
                        src="/sandplanet.png"
                        alt="Sand"
                        width="300"
                        height="300"
            />

            <motion.img initial={{ top: initialSaturnTopAtom, left: initialSaturnLeftAtom, opacity: initialSaturnOpacityAtom, transform: initialSaturnScaleAtom }}
                        animate={{ top: animateSaturnTopAtom, left: animateSaturnLeftAtom, opacity: animateSaturnOpacityAtom, transform: animateSaturnScaleAtom }}
                        transition={{ type: "tween", duration: 1 }}
                        src="/saturn.png"
                        alt="Saturn"
                        width="300"
                        height="300"
            />

            <motion.img initial={{ top: initialUranusTopAtom, left: initialUranusLeftAtom, opacity: initialUranusOpacityAtom, transform: initialUranusScaleAtom }}
                        animate={{ top: animateUranusTopAtom, left: animateUranusLeftAtom, opacity: animateUranusOpacityAtom, transform: animateUranusScaleAtom }}
                        transition={{ type: "tween", duration: 1 }}
                        src="/uranus.png"
                        alt="Uranus"
                        width="300"
                        height="300"
            />

            <motion.div
                initial={{ top: initialNeptuneTopAtom, left: initialNeptuneLeftAtom, opacity: initialNeptuneOpacityAtom, transform: initialNeptuneScaleAtom }}
                animate={{ top: animateNeptuneTopAtom, left: animateNeptuneLeftAtom, opacity: animateNeptuneOpacityAtom, transform: animateNeptuneScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >

                 <Saturn /> 

            </motion.div>

        </div>
    );
};
