/* global BigInt */
import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import ConnectInline from "components/ConnectInline";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import FlipCard, { BackCard, FrontCard } from "components/FlipCard";
import contractInterface from "contracts/contract-abi.json";
import Minting from "components/vectors/Minting";
import Modal from "components/helpers/modal";
import * as Top from "components/backgrounds/TopBackground";
import * as Bottom from "components/backgrounds/BottomBackground";
import { ReactComponent as Etherscan } from "assets/etherscan.svg";
import { ReactComponent as Opensea } from "assets/opensea.svg";
import { contractAddress } from "contracts/contractAddress";
import { parseEther } from "ethers/lib/utils.js";
import Payment from "components/Payment/Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const contractConfig = {
  addressOrName: "0xA3fC7eA538549101c420Df9a73Bc57d5fF7C8AF6",
  contractInterface: contractInterface,
};

const Mint = () => {
  const [totalMinted, setTotalMinted] = useState(0);
  const { isConnected } = useAccount();
  const [tokenID, setTokenID] = useState("0");
  const [mintedTo, setMintedTo] = useState("");

  const [flippedOne, setFlippedOne] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState();

  const flipOne = () => {
    if (flippedOne === false) setFlippedOne(true);
    else if (flippedOne === true) setFlippedOne(false);
  };

  // ==========================

  const { data: totalSupplyData, error: supplyError } = useContractRead({
    address: contractAddress,
    abi: contractInterface,
    functionName: "getTotalSupply",
    watch: true,
    args: [],
  });

  const { data: currentMintingPrice, error: priceError } = useContractRead({
    address: contractAddress,
    abi: contractInterface,
    functionName: "getTokenPrice",
    watch: true,
    args: [selectedOptionId],
  });

  console.log("currentMintingPrice: ", currentMintingPrice);
  // ========

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: contractInterface,
    functionName: "publicMint",
    overrides: {
      value: BigInt(currentMintingPrice ? currentMintingPrice : "").toString(),
    },
    args: [selectedOptionId],
  });

  const {
    data: mintData,
    isLoading: isMintLoading,
    isSuccess: isMintStarted,
    error: mintError,
    write: publicMint,
  } = useContractWrite(config);

  const { isSuccess: txSuccess, error: txError } = useWaitForTransaction({
    hash: mintData?.hash,
    wait: mintData?.wait,
    onSuccess(data) {
      console.log("Scuess data: ", data);
      setTokenID(Number(data.logs[0].topics[3]));
      setMintedTo(data?.from);
    },
  });
  useEffect(() => {
    setClassTop("idle");
    setFlippedOne(false);
  }, []);
  const PUBLISHABLE_KEY =
    "pk_live_51OVkQ6CrAy0JjG30UxyFgwQTpRSQUxvLUpcExYonii2hrwTDxy6dqKgfzlSrBBWiDNjb3b1qUJxEAvt5TL7V0fYJ00kcUXlUAv";

  // const PUBLISHABLE_KEY =
  //   "pk_test_51KqjSfH5DTXndbM5FeV5p2pkow6DMx57X4bV7AOcUzZAt3J1LHxeOdOBLUshVyKzOaDeBGJqIRt5PDFcd5JA1VLY005qzetSFm";

  // ========================
  // const { isSuccess: txSuccess, error: txError } = useWaitForTransaction({
  //   hash: mintData?.hash,
  // });

  useEffect(() => {
    if (totalSupplyData) {
      setTotalMinted(totalSupplyData?.toNumber());
    }
    if (!isMintLoading) {
    }
    if (mintError) {
      setClassTop("idle");
    }
    if (txSuccess) {
      setClassTop("success");
    }
  }, [isMintLoading, txSuccess]);

  const isMinted = txSuccess;

  const [classMinting, setClassTop] = useAtom(Top.classMintingAtom);
  const [skyColor, setSkyColor] = useAtom(Top.skyColorAtom);
  const [initialBGOpacity, setInitialBGOpacity] = useAtom(
    Top.initialBGOpacityAtom
  );
  const [animateBGOpacity, setAnimateBGOpacity] = useAtom(
    Top.animateBGOpacityAtom
  );
  const [initialStarsTop, setInitialStarsTop] = useAtom(
    Top.initialStarsTopAtom
  );
  const [animateStarsTop, setAnimateStarsTop] = useAtom(
    Top.animateStarsTopAtom
  );
  const [initialStarsLeft, setInitialStarsLeft] = useAtom(
    Top.initialStarsLeftAtom
  );
  const [animateStarsLeft, setAnimateStarsLeft] = useAtom(
    Top.animateStarsLeftAtom
  );
  const [initialStarsOpacity, setInitialStarsOpacity] = useAtom(
    Top.initialStarsOpacityAtom
  );
  const [animateStarsOpacity, setAnimateStarsOpacity] = useAtom(
    Top.animateStarsOpacityAtom
  );
  const [initialSunTop, setInitialSunTop] = useAtom(Top.initialSunTopAtom);
  const [animateSunTop, setAnimateSunTop] = useAtom(Top.animateSunTopAtom);
  const [initialSunLeft, setInitialSunLeft] = useAtom(Top.initialSunLeftAtom);
  const [animateSunLeft, setAnimateSunLeft] = useAtom(Top.animateSunLeftAtom);

  const [initialMainBottom, setInitialMainBottom] = useAtom(
    Bottom.initialMainBottomAtom
  );
  const [animateMainBottom, setAnimateMainBottom] = useAtom(
    Bottom.animateMainBottomAtom
  );
  const [initialMainLeft, setInitialMainLeft] = useAtom(
    Bottom.initialMainLeftAtom
  );
  const [animateMainLeft, setAnimateMainLeft] = useAtom(
    Bottom.animateMainLeftAtom
  );
  const [initialMainOpacity, setInitialMainOpacity] = useAtom(
    Bottom.initialMainOpacityAtom
  );
  const [animateMainOpacity, setAnimateMainOpacity] = useAtom(
    Bottom.animateMainOpacityAtom
  );
  const [initialMainScale, setInitialMainScale] = useAtom(
    Bottom.initialMainScaleAtom
  );
  const [animateMainScale, setAnimateMainScale] = useAtom(
    Bottom.animateMainScaleAtom
  );
  const [initialMintBottom, setInitialMintBottom] = useAtom(
    Bottom.initialMintBottomAtom
  );
  const [animateMintBottom, setAnimateMintBottom] = useAtom(
    Bottom.animateMintBottomAtom
  );
  const [initialMintLeft, setInitialMintLeft] = useAtom(
    Bottom.initialMintLeftAtom
  );
  const [animateMintLeft, setAnimateMintLeft] = useAtom(
    Bottom.animateMintLeftAtom
  );
  const [initialMintOpacity, setInitialMintOpacity] = useAtom(
    Bottom.initialMintOpacityAtom
  );
  const [animateMintOpacity, setAnimateMintOpacity] = useAtom(
    Bottom.animateMintOpacityAtom
  );
  const [initialMintScale, setInitialMintScale] = useAtom(
    Bottom.initialMintScaleAtom
  );
  const [animateMintScale, setAnimateMintScale] = useAtom(
    Bottom.animateMintScaleAtom
  );
  const [initialTimelineBottom, setInitialTimelineBottom] = useAtom(
    Bottom.initialTimelineBottomAtom
  );
  const [animateTimelineBottom, setAnimateTimelineBottom] = useAtom(
    Bottom.animateTimelineBottomAtom
  );
  const [initialTimelineLeft, setInitialTimelineLeft] = useAtom(
    Bottom.initialTimelineLeftAtom
  );
  const [animateTimelineLeft, setAnimateTimelineLeft] = useAtom(
    Bottom.animateTimelineLeftAtom
  );
  const [initialTimelineOpacity, setInitialTimelineOpacity] = useAtom(
    Bottom.initialTimelineOpacityAtom
  );
  const [animateTimelineOpacity, setAnimateTimelineOpacity] = useAtom(
    Bottom.animateTimelineOpacityAtom
  );
  const [initialTimelineScale, setInitialTimelineScale] = useAtom(
    Bottom.initialTimelineScaleAtom
  );
  const [animateTimelineScale, setAnimateTimelineScale] = useAtom(
    Bottom.animateTimelineScaleAtom
  );
  const [initialAboutBottom, setInitialAboutBottom] = useAtom(
    Bottom.initialAboutBottomAtom
  );
  const [animateAboutBottom, setAnimateAboutBottom] = useAtom(
    Bottom.animateAboutBottomAtom
  );
  const [initialAboutLeft, setInitialAboutLeft] = useAtom(
    Bottom.initialAboutLeftAtom
  );
  const [animateAboutLeft, setAnimateAboutLeft] = useAtom(
    Bottom.animateAboutLeftAtom
  );
  const [initialAboutOpacity, setInitialAboutOpacity] = useAtom(
    Bottom.initialAboutOpacityAtom
  );
  const [animateAboutOpacity, setAnimateAboutOpacity] = useAtom(
    Bottom.animateAboutOpacityAtom
  );
  const [initialAboutScale, setInitialAboutScale] = useAtom(
    Bottom.initialAboutScaleAtom
  );
  const [animateAboutScale, setAnimateAboutScale] = useAtom(
    Bottom.animateAboutScaleAtom
  );
  const [initialFoundersBottom, setInitialFoundersBottom] = useAtom(
    Bottom.initialFoundersBottomAtom
  );
  const [animateFoundersBottom, setAnimateFoundersBottom] = useAtom(
    Bottom.animateFoundersBottomAtom
  );
  const [initialFoundersLeft, setInitialFoundersLeft] = useAtom(
    Bottom.initialFoundersLeftAtom
  );
  const [animateFoundersLeft, setAnimateFoundersLeft] = useAtom(
    Bottom.animateFoundersLeftAtom
  );
  const [initialFoundersOpacity, setInitialFoundersOpacity] = useAtom(
    Bottom.initialFoundersOpacityAtom
  );
  const [animateFoundersOpacity, setAnimateFoundersOpacity] = useAtom(
    Bottom.animateFoundersOpacityAtom
  );
  const [initialFoundersScale, setInitialFoundersScale] = useAtom(
    Bottom.initialFoundersScaleAtom
  );
  const [animateFoundersScale, setAnimateFoundersScale] = useAtom(
    Bottom.animateFoundersScaleAtom
  );
  const [initialVisionBottom, setInitialVisionBottom] = useAtom(
    Bottom.initialVisionBottomAtom
  );
  const [animateVisionBottom, setAnimateVisionBottom] = useAtom(
    Bottom.animateVisionBottomAtom
  );
  const [initialVisionLeft, setInitialVisionLeft] = useAtom(
    Bottom.initialVisionLeftAtom
  );
  const [animateVisionLeft, setAnimateVisionLeft] = useAtom(
    Bottom.animateVisionLeftAtom
  );
  const [initialVisionOpacity, setInitialVisionOpacity] = useAtom(
    Bottom.initialVisionOpacityAtom
  );
  const [animateVisionOpacity, setAnimateVisionOpacity] = useAtom(
    Bottom.animateVisionOpacityAtom
  );
  const [initialVisionScale, setInitialVisionScale] = useAtom(
    Bottom.initialVisionScaleAtom
  );
  const [animateVisionScale, setAnimateVisionScale] = useAtom(
    Bottom.animateVisionScaleAtom
  );
  const [initialTokenomicsBottom, setInitialTokenomicsBottom] = useAtom(
    Bottom.initialTokenomicsBottomAtom
  );
  const [animateTokenomicsBottom, setAnimateTokenomicsBottom] = useAtom(
    Bottom.animateTokenomicsBottomAtom
  );
  const [initialTokenomicsLeft, setInitialTokenomicsLeft] = useAtom(
    Bottom.initialTokenomicsLeftAtom
  );
  const [animateTokenomicsLeft, setAnimateTokenomicsLeft] = useAtom(
    Bottom.animateTokenomicsLeftAtom
  );
  const [initialTokenomicsOpacity, setInitialTokenomicsOpacity] = useAtom(
    Bottom.initialTokenomicsOpacityAtom
  );
  const [animateTokenomicsOpacity, setAnimateTokenomicsOpacity] = useAtom(
    Bottom.animateTokenomicsOpacityAtom
  );
  const [initialTokenomicsScale, setInitialTokenomicsScale] = useAtom(
    Bottom.initialTokenomicsScaleAtom
  );
  const [animateTokenomicsScale, setAnimateTokenomicsScale] = useAtom(
    Bottom.animateTokenomicsScaleAtom
  );

  const [initialTitleTop, setInitialTitleTop] = useAtom(
    Bottom.initialTitleTopAtom
  );
  const [animateTitleTop, setAnimateTitleTop] = useAtom(
    Bottom.animateTitleTopAtom
  );
  const [initialTitleLeft, setInitialTitleLeft] = useAtom(
    Bottom.initialTitleLeftAtom
  );
  const [animateTitleLeft, setAnimateTitleLeft] = useAtom(
    Bottom.animateTitleLeftAtom
  );
  const [initialTitleOpacity, setInitialTitleOpacity] = useAtom(
    Bottom.initialTitleOpacityAtom
  );
  const [animateTitleOpacity, setAnimateTitleOpacity] = useAtom(
    Bottom.animateTitleOpacityAtom
  );
  const [initialTitleScale, setInitialTitleScale] = useAtom(
    Bottom.initialTitleScaleAtom
  );
  const [animateTitleScale, setAnimateTitleScale] = useAtom(
    Bottom.animateTitleScaleAtom
  );

  useEffect(() => {
    setSkyColor("#288fe3");
    setInitialBGOpacity(animateBGOpacity);
    setAnimateBGOpacity(1);
  });

  useEffect(() => {
    setInitialStarsTop(animateStarsTop);
    setInitialStarsLeft(animateStarsLeft);
    setInitialStarsOpacity(animateStarsOpacity);
    setAnimateStarsTop("0px");
    setAnimateStarsLeft("0px");
    setAnimateStarsOpacity(1);
    setInitialSunTop(animateSunTop);
    setInitialSunLeft(animateSunLeft);
    setAnimateSunTop("15rem");
    setAnimateSunLeft("16rem");
  });

  useEffect(() => {
    setInitialTitleTop(animateTitleTop);
    setInitialTitleLeft(animateTitleLeft);
    setInitialTitleOpacity(animateTitleOpacity);
    setInitialTitleScale(animateTitleScale);

    setInitialMainBottom(animateMainBottom);
    setInitialMainLeft(animateMainLeft);
    setInitialMainOpacity(animateMainOpacity);
    setInitialMainScale(animateMainScale);
    setInitialMintBottom(animateMintBottom);
    setInitialMintLeft(animateMintLeft);
    setInitialMintOpacity(animateMintOpacity);
    setInitialMintScale(animateMintScale);
    setInitialTimelineBottom(animateTimelineBottom);
    setInitialTimelineLeft(animateTimelineLeft);
    setInitialTimelineOpacity(animateTimelineOpacity);
    setInitialTimelineScale(animateTimelineScale);
    setInitialAboutBottom(animateAboutBottom);
    setInitialAboutLeft(animateAboutLeft);
    setInitialAboutOpacity(animateAboutOpacity);
    setInitialAboutScale(animateAboutScale);
    setInitialFoundersBottom(animateFoundersBottom);
    setInitialFoundersLeft(animateFoundersLeft);
    setInitialFoundersOpacity(animateFoundersOpacity);
    setInitialFoundersScale(animateFoundersScale);
    setInitialVisionBottom(animateVisionBottom);
    setInitialVisionLeft(animateVisionLeft);
    setInitialVisionOpacity(animateVisionOpacity);
    setInitialVisionScale(animateVisionScale);
    setInitialTokenomicsBottom(animateTokenomicsBottom);
    setInitialTokenomicsLeft(animateTokenomicsLeft);
    setInitialTokenomicsOpacity(animateTokenomicsOpacity);
    setInitialTokenomicsScale(animateTokenomicsScale);

    setAnimateTitleTop("75vh");
    setAnimateTitleLeft("unset");
    setAnimateTitleOpacity(1);
    setAnimateTitleScale("scale(0.75)");

    setAnimateMainBottom("-0vh");
    setAnimateMainLeft("-0vw");
    setAnimateMainOpacity(0);
    setAnimateMainScale("scale(1)");
    setAnimateMintBottom("-0vh");
    setAnimateMintLeft("-0vw");
    setAnimateMintOpacity(1);
    setAnimateMintScale("scale(1)");
    setAnimateTimelineBottom("-0vh");
    setAnimateTimelineLeft("-0vw");
    setAnimateTimelineOpacity(0);
    setAnimateTimelineScale("scale(1)");
    setAnimateAboutBottom("-0vh");
    setAnimateAboutLeft("-0vw");
    setAnimateAboutOpacity(0);
    setAnimateAboutScale("scale(1)");
    setAnimateFoundersBottom("-0vh");
    setAnimateFoundersLeft("-0vw");
    setAnimateFoundersOpacity(0);
    setAnimateFoundersScale("scale(1)");
    setAnimateVisionBottom("-0vh");
    setAnimateVisionLeft("-0vw");
    setAnimateVisionOpacity(0);
    setAnimateVisionScale("scale(1)");
    setAnimateTokenomicsBottom("-0vh");
    setAnimateTokenomicsLeft("-0vw");
    setAnimateTokenomicsOpacity(0);
    setAnimateTokenomicsScale("scale(1)");
  });

  const trigger = () => {
    setClassTop("minting");
    publicMint();
  };

  const dropdownOptions = [
    {
      id: 1,
      token_type: "Partners PMF Tokens",
      image:
        "https://ipfs.io/ipfs/QmXCqfzRLRqcrZLzHsHHw831gtNdg64WGkvg4HGAcKab8A",
      quantity: 80,
      price_per_token: "$100,000.00",
      amount: 100000,
      payment_link: "https://buy.stripe.com/dR69C24vIdKw6vC7sw",
      "Total Equity": "12.00%",
      "Equity per T": "0.07%",
      Royalty: "20%",
      "Royalty Rev": "8.00%",
      "Voting Right": "50% of equi",
      access_utility:
        "Access to DAO utilities, Buddha Boy artwork, influencer access, selected events",
    },
    {
      id: 2,
      token_type: "Founders PMF Tokens",
      image: "/NFT.jpg",
      quantity: 60,
      price_per_token: "$26,000.00",
      amount: 26000,
      payment_link: "",
      "Total Equity": "8.00%",
      "Equity per T": "0.02%",
      Royalty: "20%",
      "Royalty Rev": "6.00%",
      "Voting Right": "30% of equi",
      access_utility:
        "Access to basic DAO utilities, Buddha Boy artwork, selected events",
    },
    {
      id: 3,
      token_type: "Funder Associate Tokens",
      image:
        "https://ipfs.io/ipfs/QmZridv9ebAD9WtWkdPeV7Uf2jfUmS1sEH17nubNpausEs",
      quantity: 10,
      price_per_token: "$30,000.00",
      amount: 30000,
      payment_link: "https://buy.stripe.com/cN215w9Q2fSEbPW8ww",
      "Total Equity": "7.00%",
      "Equity per T": "0.07%",
      Royalty: "20%",
      "Royalty Rev": "5.00%",
      "Voting Right": "25% of equi",
      access_utility: "NBA Fan Boy NFT art, selected PMF access",
    },
    {
      id: 4,
      token_type: "Funder Community Tokens",
      image: "/funder.png",
      quantity: 50,
      price_per_token: "$3,600.00",
      amount: 3600,
      payment_link: "https://buy.stripe.com/6oE9C2aU6aykf289AD",
      "Total Equity": "3.00%",
      "Equity per T": "0.01%",
      Royalty: "20%",
      "Royalty Rev": "1.30%",
      "Voting Right": "20% of equi",
      access_utility: "NBA Fan Boy NFT art, selected PMF access",
    },
    {
      id: 5,
      token_type: "Sponsor Tokens",
      image: "/community-token.png",
      quantity: 52000,
      price_per_token: "$520.00",
      amount: 520,
      payment_link: "https://buy.stripe.com/cN2cOeaU6ayk9HO3ce",
      "Total Equity": "2.00%",
      "Equity per T": "0.00%",
      Royalty: "20%",
      "Royalty Rev": "1.00%",
      "Voting Right": "10% of equi",
      access_utility: "Local workshops, selected PMF access",
    },
    {
      id: 6,
      token_type: "Participant Tokens",
      image: "/community-token.png",
      quantity: 81000,
      price_per_token: "$260.00",
      amount: 260,
      payment_link: "https://buy.stripe.com/28o15w9Q2gWI1bi7st",
      "Total Equity": "0.70%",
      "Equity per T": "0.00%",
      Royalty: "20%",
      "Royalty Rev": "0.70%",
      "Voting Right": "10% of equi",
      access_utility: "Special offers for early buyers, selected PMF access",
    },
  ];

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const Dropdown = ({ options, selectedValue, onSelect }) => {
    return (
      <div className="dropdown">
        <select
          value={selectedValue ? JSON.stringify(selectedValue) : ""}
          onChange={(e) => onSelect(JSON.parse(e.target.value))}
        >
          <option value={selectedOption ? selectedOption : ""} hidden>
            {selectedOption ? selectedOption?.token_type : "Select One"}
          </option>
          {options.map((option, index) => (
            <option key={index} value={JSON.stringify(option)}>
              {option.token_type}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handlePayment = () => {
    console.log("hello");
    if (selectedOptionId) {
      setShowPaymentModal(true);
    }
  };

  // const handleOptionSelect = (option) => {
  //   setSelectedOption(JSON.parse(option));
  // };

  const handleOptionSelect = (option) => {
    console.log("SELECTED OPTION: ", option);
    setSelectedOption(option);
    const newQuantity = option.id + 1;
    setSelectedOptionId(newQuantity);
  };

  return (
    <div className="RouteWrapper MintWrapper">
      <div className="overlay"></div>

      {/* ### BACKGROUND ANIMATION WHILE MINTING ### */}

      <div className={`psych ${classMinting}`}>
        <Minting />
      </div>

      {/* ### SKY BACKGROUND ### */}

      <Top.TopBackground
        classMintingAtom={classMinting}
        skyColorAtom={skyColor}
        initialBGOpacityAtom={initialBGOpacity}
        animateBGOpacityAtom={animateBGOpacity}
        initialStarsTopAtom={initialStarsTop}
        animateStarsTopAtom={animateStarsTop}
        initialStarsLeftAtom={initialStarsLeft}
        animateStarsLeftAtom={animateStarsLeft}
        initialStarsOpacityAtom={initialStarsOpacity}
        animateStarsOpacityAtom={animateStarsOpacity}
        initialSunTopAtom={initialSunTop}
        animateSunTopAtom={animateSunTop}
        initialSunLeftAtom={initialSunLeft}
        animateSunLeftAtom={animateSunLeft}
      />

      <div className={`Base ${classMinting}`}>
        <div className="viewport">
          {/* ### CARD ### */}

          <motion.div
            className={`container Mint ${classMinting} mintContainer`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "tween", duration: 0.4 }}
          >
            {/* ### LEFT CARD ### */}

            <div style={{ width: "100%" }} className={`Mint ${classMinting}`}>
              {/* <div style={{ padding: "24px 24px 24px 24px" }}> */}
              <div>
                <h1>MCAA Bud Dah Boy</h1>

                <p style={{ margin: "0 0 4rem 0" }}>
                  {totalMinted} Bud Dah Boy minted.
                </p>

                {mintError && (
                  <p style={{ marginTop: 24, color: "#FF6257" }}>
                    d Error: {mintError.message}
                  </p>
                )}

                {txError && (
                  <p style={{ marginTop: 24, color: "#FF6257" }}>
                    Error: {txError.message}
                  </p>
                )}

                {isConnected && !isMinted && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className="MintCards"
                    >
                      <div className="" onClick={() => flipOne()}>
                        <FlipCard height={295}>
                          <FrontCard
                            isCardFlipped={flippedOne}
                            style={{ position: "relative" }}
                          >
                            <img
                              className="responsive"
                              src={
                                selectedOption && selectedOption.image
                                  ? selectedOption.image
                                  : "/NFT.jpg"
                              }
                              alt="NFT"
                            />
                          </FrontCard>
                        </FlipCard>
                      </div>
                    </div>
                    <Dropdown
                      options={dropdownOptions}
                      onSelect={handleOptionSelect}
                      selectedValue={selectedOption}
                    />

                    {selectedOption && (
                      <div
                        style={{
                          marginTop: 5,
                          marginottom: "160px",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <strong>Price:</strong>{" "}
                          {selectedOption?.price_per_token}
                        </div>
                        <div
                          className="access_utility"
                          style={{ fontSize: "15px" }}
                        >
                          <strong style={{ fontSize: "16px" }}>
                            Access Utility:
                          </strong>{" "}
                          {selectedOption.access_utility}
                        </div>
                      </div>
                    )}
                    <div>
                      <button
                        style={{ marginTop: 10 }}
                        disabled={isMintLoading || isMintStarted || !publicMint}
                        className={`button lg mint ${classMinting}`}
                        data-mint-loading={isMintLoading}
                        data-mint-started={isMintStarted}
                        onClick={() => trigger()}
                      >
                        {isMintLoading && "Waiting for approval"}
                        {isMintStarted && "Minting..."}
                        {!isMintLoading && !isMintStarted && "Mint NFT"}
                      </button>
                    </div>
                    <div className="stripe-btn">
                      <button
                        type="button"
                        className={`button lg mint ${classMinting}`}
                        onClick={handlePayment}
                      >
                        Pay with Credit
                      </button>
                    </div>
                  </>
                )}

                {isConnected && isMinted && (
                  <p
                    style={{
                      marginBottom: 6,
                      position: "relative",
                      zIndex: 2,
                      left: "0rem",
                      bottom: 0,
                      verticalAlign: "top",
                    }}
                  >
                    {" "}
                    Find it on &nbsp;&nbsp;&nbsp;
                    <a
                      href={`https://sepolia.etherscan.io/tx/${mintData?.hash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Etherscan /> &nbsp;{" "}
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      // href={`https://testnets.opensea.io/assets/ethereum/${mintedTo}/${tokenID}`}
                      href={`https://testnets.opensea.io/assets/sepolia/${contractAddress}/${tokenID}`}
                    >
                      <Opensea />
                    </a>
                  </p>
                )}

                {!isConnected && (
                  <div className="MainConnect">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className="MintCards"
                    >
                      <div className="" onClick={() => flipOne()}>
                        <FlipCard height={295}>
                          <FrontCard
                            isCardFlipped={flippedOne}
                            style={{ position: "relative" }}
                          >
                            <img
                              className="responsive"
                              src={
                                selectedOption && selectedOption.image
                                  ? selectedOption.image
                                  : "/NFT.jpg"
                              }
                              alt="NFT"
                            />
                          </FrontCard>
                        </FlipCard>
                      </div>
                    </div>
                    <Dropdown
                      options={dropdownOptions}
                      onSelect={handleOptionSelect}
                      selectedValue={selectedOption?.token_type}
                    />

                    {selectedOption && (
                      <div
                        style={{
                          marginTop: 5,
                          marginottom: "160px",
                          fontSize: "16px",
                        }}
                      >
                        <div>
                          <strong>Price:</strong>{" "}
                          {selectedOption?.price_per_token}
                        </div>
                        <div
                          className="access_utility"
                          style={{ fontSize: "15px" }}
                        >
                          <strong style={{ fontSize: "16px" }}>
                            Access Utility:
                          </strong>{" "}
                          {selectedOption.access_utility}
                        </div>
                      </div>
                    )}
                    <ConnectInline handlePayment={handlePayment} />
                    <MediaQuery maxWidth={749}>
                      <button
                        type="button"
                        className="stripe-button"
                        onClick={handlePayment}
                      >
                        Pay with Credit
                      </button>
                    </MediaQuery>
                  </div>
                )}
              </div>
            </div>

            {/* ### RIGHT CARD ### */}

            <div
              style={{ flex: "0 0 auto" }}
              className={`NFTContainer ${classMinting}`}
            >
              <FlipCard>
                <FrontCard isCardFlipped={isMinted}>
                  <div style={{ padding: 2 }}>
                    <img
                      src="/preview/0003.jpeg"
                      width="230"
                      height="230"
                      alt="Bud Dah Boy"
                      style={{
                        borderRadius: "20rem",
                        position: "absolute",
                        maxWidth: "65vw",
                        width: "100%",
                      }}
                    />
                  </div>
                </FrontCard>

                <BackCard isCardFlipped={isMinted}>
                  <div style={{ padding: 2 }}>
                    <img
                      src="/preview/0003.jpeg"
                      width="230"
                      height="230"
                      alt="Bud Dah Boy"
                      style={{
                        borderRadius: "20rem",
                        position: "absolute",
                        maxWidth: "65vw",
                        width: "100%",
                      }}
                    />
                  </div>
                </BackCard>
              </FlipCard>
            </div>
          </motion.div>
        </div>

        {/* helpers */}

        <Elements stripe={loadStripe(PUBLISHABLE_KEY)}>
          <Payment
            show={showPaymentModal}
            tier={selectedOptionId}
            amount={selectedOption?.amount}
            payment_link={selectedOption?.payment_link}
            onClose={() => setShowPaymentModal(false)}
          />
        </Elements>

        {/* <Modal
          className="p10"
          activator={({ setShow }) => (
            <button
              className="help-button"
              type="button"
              onClick={() => setShow(true)}
            >
              Help?
            </button>
          )}
        >
          <h2 className="mb-5">
            <b>Help FAQ</b>
          </h2>

          <p className="border-b pb-4 text-base">
            If you need help setting up a wallet for your browser on a computer
            please see{" "}
            <a
              href="https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en"
              target="_blank"
              rel="noreferrer noopener"
            >
              <u>Coinbase Wallet</u>
            </a>{" "}
            or{" "}
            <a
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
              target="_blank"
              rel="noreferrer noopener"
            >
              <u>Metamask</u>
            </a>
          </p>
          <h3 className="font-bold text-lg mb-5">Having issues?</h3>
          <p className="text-base border-b pb-4">
            We recommend using Google Chrome, Firefox or Brave Browser for
            better support. You can use <b>Metamask</b> or{" "}
            <b>Coinbase Wallet</b>, <b>Brave Browser</b> also has a built wallet
            supporting both Ethereum and Binance network.
          </p>
          <h4 className="font-bold mb-2 text-lg">Can't login</h4>
          <p className="text-base">
            - If nothing happens, check your wallet extension and unlock it then
            try again.
          </p>
          <p className="border-b pb-4"></p>
          <p className="border-b pb-4">
            <a
              href="https://metamask.io/buy-crypto/"
              target="_blank"
              rel="noreferrer noopener"
            >
              How to buy crypto currency in metamask using cards, banks of
              digital wallets tutorial
            </a>
          </p>
          <h3 className="font-bold text-lg mb-5">Contact us</h3>
          <p className="text-base">
            Join the{" "}
            <a
              className="button"
              href="https://discord.gg/sMefg2cDF4"
              target="_blank"
              rel="noreferrer noopener"
            >
              MCAA Discord Server
            </a>
          </p>
        </Modal> */}

        {/* ### BOTTOM BACKGROUND ### */}

        {/*<motion.div className="BG transition"
                        initial={{ left: '100vw', opacity: 1, transform: 'scale(0.15)' }}
                        animate={{ left: '0', top: '-75%', opacity: 1, transform: 'scale(1)' }}
                        transition={{ type: "tween", duration: 2 }}
                    >

                        <img src="/flag.png" alt="Train" className="Train" priority="true" />

                    </motion.div>*/}

        <motion.div
          className="BG transition"
          initial={{
            zIndex: 4,
            left: "100vw",
            opacity: 1,
            transform: "scale(0.15)",
          }}
          animate={{
            zIndex: 3,
            left: "-150vw",
            opacity: 1,
            transform: "scale(1.35)",
          }}
          transition={{ type: "tween", duration: 2 }}
        >
          <img src="/train.png" alt="Train" className="Train" priority="true" />
        </motion.div>

        <Bottom.BottomBackground
          initialMainBottomAtom={initialMainBottom}
          animateMainBottomAtom={animateMainBottom}
          initialMainLeftAtom={initialMainLeft}
          animateMainLeftAtom={animateMainLeft}
          initialMainOpacityAtom={initialMainOpacity}
          animateMainOpacityAtom={animateMainOpacity}
          initialMainScaleAtom={initialMainScale}
          animateMainScaleAtom={animateMainScale}
          initialMintBottomAtom={initialMintBottom}
          animateMintBottomAtom={animateMintBottom}
          initialMintLeftAtom={initialMintLeft}
          animateMintLeftAtom={animateMintLeft}
          initialMintOpacityAtom={initialMintOpacity}
          animateMintOpacityAtom={animateMintOpacity}
          initialMintScaleAtom={initialMintScale}
          animateMintScaleAtom={animateMintScale}
          initialTimelineBottomAtom={initialTimelineBottom}
          animateTimelineBottomAtom={animateTimelineBottom}
          initialTimelineLeftAtom={initialTimelineLeft}
          animateTimelineLeftAtom={animateTimelineLeft}
          initialTimelineOpacityAtom={initialTimelineOpacity}
          animateTimelineOpacityAtom={animateTimelineOpacity}
          initialTimelineScaleAtom={initialTimelineScale}
          animateTimelineScaleAtom={animateTimelineScale}
          initialAboutBottomAtom={initialAboutBottom}
          animateAboutBottomAtom={animateAboutBottom}
          initialAboutLeftAtom={initialAboutLeft}
          animateAboutLeftAtom={animateAboutLeft}
          initialAboutOpacityAtom={initialAboutOpacity}
          animateAboutOpacityAtom={animateAboutOpacity}
          initialAboutScaleAtom={initialAboutScale}
          animateAboutScaleAtom={animateAboutScale}
          initialFoundersBottomAtom={initialFoundersBottom}
          animateFoundersBottomAtom={animateFoundersBottom}
          initialFoundersLeftAtom={initialFoundersLeft}
          animateFoundersLeftAtom={animateFoundersLeft}
          initialFoundersOpacityAtom={initialFoundersOpacity}
          animateFoundersOpacityAtom={animateFoundersOpacity}
          initialFoundersScaleAtom={initialFoundersScale}
          animateFoundersScaleAtom={animateFoundersScale}
          initialVisionBottomAtom={initialVisionBottom}
          animateVisionBottomAtom={animateVisionBottom}
          initialVisionLeftAtom={initialVisionLeft}
          animateVisionLeftAtom={animateVisionLeft}
          initialVisionOpacityAtom={initialVisionOpacity}
          animateVisionOpacityAtom={animateVisionOpacity}
          initialVisionScaleAtom={initialVisionScale}
          animateVisionScaleAtom={animateVisionScale}
          initialTokenomicsBottomAtom={initialTokenomicsBottom}
          animateTokenomicsBottomAtom={animateTokenomicsBottom}
          initialTokenomicsLeftAtom={initialTokenomicsLeft}
          animateTokenomicsLeftAtom={animateTokenomicsLeft}
          initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
          animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
          initialTokenomicsScaleAtom={initialTokenomicsScale}
          animateTokenomicsScaleAtom={animateTokenomicsScale}
          initialTitleTopAtom={initialTitleTop}
          animateTitleTopAtom={animateTitleTop}
          initialTitleLeftAtom={initialTitleLeft}
          animateTitleLeftAtom={animateTitleLeft}
          initialTitleOpacityAtom={initialTitleOpacity}
          animateTitleOpacityAtom={animateTitleOpacity}
          initialTitleScaleAtom={initialTitleScale}
          animateTitleScaleAtom={animateTitleScale}
        />
      </div>
    </div>
  );
};

export default Mint;
