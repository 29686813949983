import { useState } from 'react';
import './styles/Landing.css';
import Draggable from 'react-draggable';

function Landing() {
  const [zoomInClassName, setZoomInClassName] = useState('');
  const [fadeOutClassName, setFadeOutClassName] = useState('');

  const DraggableEventHandlerStart = (a, b, c) => {
    setZoomInClassName('in');
  };

  const DraggableEventHandlerStop = (a, b, c) => {
    const pageHeight = a.view.innerHeight;
    let position = a.pageY;
    if (typeof position === 'undefined' && typeof a.changedTouches[0] !== 'undefined') {
      position = a.changedTouches[0].pageY;
    }
    const percent = position / pageHeight;
    console.log(percent);

    if (percent > 0.45) {
      setFadeOutClassName('fade-out');
      setTimeout(() => {
        setZoomInClassName('');
        window.location.href = '/home';
      }, 1000);
    }
  };

  const DraggableEventHandlerDraging = (a, b, c) => {
    const pageHeight = a.view.innerHeight;
    let position = a.pageY;
    if (typeof position === 'undefined' && typeof a.changedTouches[0] !== 'undefined') {
      position = a.changedTouches[0].pageY;
    }
    const percent = position / pageHeight;
    console.log(percent);

    if (percent < 0.45) {
      window.location.href = '/home';
      return false;
    }
  };

  return (
    <main className={fadeOutClassName}>
      <div id="bg">
        <section id="wrapper">
          <article>
            <div id="bin" className={`zoom ${zoomInClassName}`}>
              <div id="innerBin"></div>
            </div>
            <Draggable
              axis="y"
              onStart={DraggableEventHandlerStart}
              onStop={DraggableEventHandlerStop}
              onDrag={DraggableEventHandlerDraging}
            >
              <div className="drag" id="truck" />
            </Draggable>
          </article>
        </section>
        <div id="Arrow" className="arrow">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </main>
  );
}

export default Landing;