import React from "react";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";

const PaymentModal = ({ children, show, onClose }) => {
  const content = (
    <div className="overlayModal">
      <div className="modal">
        <span className="modal-close" type="button" onClick={onClose}>
          X
        </span>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );

  return createPortal(
    <CSSTransition
      in={show}
      timeout={120}
      classNames="modal-transition"
      unmountOnExit
    >
      {() => <div>{content}</div>}
    </CSSTransition>,
    document.body
  );
};

export default PaymentModal;
