import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "Home";
import MCAA from "MCAA";
import Mint from "Mint";
import MintUpdated from "Mint-Updated";
import Vision from "Vision";
import About from "About";
import Roadmap from "Roadmap";
import Founders from "Founders";
import Tokenomics from "Tokenomics";
import BuddhaBoys from "BuddhaBoys";
import Landing from "Landing";

import { AnimatePresence } from "framer-motion";

function AnimtedRoutes() {
  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/home" element={<Home />} />
        <Route path="/map" element={<MCAA />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/mint-updated" element={<MintUpdated />} />
        <Route path="/about" element={<About />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/founders" element={<Founders />} />
        <Route path="/tokenomics" element={<Tokenomics />} />
        <Route path="/buddhaBoys" element={<BuddhaBoys />} />
      </Routes>
    </AnimatePresence>
  );
}
export default AnimtedRoutes;
