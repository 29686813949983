import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "styles/index.css";

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
// WAGMI
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, goerli, sepolia } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { infuraProvider } from "wagmi/providers/infura";
import App from "App";

import { Toaster } from "react-hot-toast";

// const { chains, provider } = configureChains(
//   [mainnet, goerli, sepolia],
//   [
//     infuraProvider({ infuraId: process.env.REACT_APP_INFURA_ID }),
//     alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID_GOERLI }),
//   ]
// );

const { chains, provider } = configureChains(
  [mainnet],
  [
    infuraProvider({ infuraId: "430d378c6f484c18838cd6256f7fa4c2" }),
    alchemyProvider({ alchemyId: "xcKI3gw2qjScWI9s9Yx-CDwO8fraFOcL" }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "MCAA NFT",
  chains,
});

console.log("provider: ", provider);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const TCATheme = {
  colors: {
    accentColor: "white",
    accentColorForeground: "none",
    actionButtonBorder: "none",
    actionButtonBorderMobile: "none",
    actionButtonSecondaryBackground: "none",
    closeButton: "none",
    closeButtonBackground: "none",
    connectButtonBackground: "none",
    connectButtonBackgroundError: "none",
    connectButtonInnerBackground: "none",
    connectButtonText: "white",
    connectButtonTextError: "none",
    connectionIndicator: "none",
    error: "none",
    generalBorder: "none",
    generalBorderDim: "none",
    menuItemBackground: "none",
    modalBackdrop: "none",
    modalBackground: "none",
    modalBorder: "none",
    modalText: "none",
    modalTextDim: "none",
    modalTextSecondary: "none",
    profileAction: "none",
    profileActionHover: "none",
    profileForeground: "none",
    selectedOptionBorder: "none",
    standby: "none",
  },
  fonts: {
    body: "Helvetica",
  },
  radii: {
    actionButton: "none",
    connectButton: "none",
    menuButton: "none",
    modal: "none",
    modalMobile: "none",
  },
  shadows: {
    connectButton: "none",
    dialog: "none",
    profileDetailsAction: "none",
    selectedOption: "none",
    selectedWallet: "none",
    walletLogo: "none",
  },
  blurs: {
    modalOverlay: "",
  },
};

root.render(
  <React.StrictMode>
    <Toaster position="top-right" />

    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={TCATheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);
