import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import {
    useAccount
} from 'wagmi';
import Connect from 'components/Connect';
import Modal from 'components/helpers/modal';
import NF from 'components/Files';
import SF from 'components/SecondFiles';
import TF from 'components/ThirdFiles';
import * as Top from 'components/backgrounds/TopBackground';
import * as Bottom from 'components/backgrounds/BottomBackground';
import * as Over from 'components/backgrounds/Planets';
import 'styles/modal.css'

const BuddhaBoys = () => {

    const { isConnected } = useAccount();

    const [ skyColor, setSkyColor ] = useAtom(Top.skyColorAtom);
    const [ initialBGOpacity, setInitialBGOpacity ] = useAtom(Top.initialBGOpacityAtom);
    const [ animateBGOpacity, setAnimateBGOpacity ] = useAtom(Top.animateBGOpacityAtom);
    const [ initialStarsTop, setInitialStarsTop ] = useAtom(Top.initialStarsTopAtom);
    const [ animateStarsTop, setAnimateStarsTop ] = useAtom(Top.animateStarsTopAtom);
    const [ initialStarsLeft, setInitialStarsLeft ] = useAtom(Top.initialStarsLeftAtom);
    const [ animateStarsLeft, setAnimateStarsLeft ] = useAtom(Top.animateStarsLeftAtom);
    const [ initialStarsOpacity, setInitialStarsOpacity ] = useAtom(Top.initialStarsOpacityAtom);
    const [ animateStarsOpacity, setAnimateStarsOpacity ] = useAtom(Top.animateStarsOpacityAtom);
    const [ initialSunTop, setInitialSunTop ] = useAtom(Top.initialSunTopAtom);
    const [ animateSunTop, setAnimateSunTop ] = useAtom(Top.animateSunTopAtom);
    const [ initialSunLeft, setInitialSunLeft ] = useAtom(Top.initialSunLeftAtom);
    const [ animateSunLeft, setAnimateSunLeft ] = useAtom(Top.animateSunLeftAtom);

    const [ initialMainBottom, setInitialMainBottom ] = useAtom(Bottom.initialMainBottomAtom);
    const [ animateMainBottom, setAnimateMainBottom ] = useAtom(Bottom.animateMainBottomAtom);
    const [ initialMainLeft, setInitialMainLeft ] = useAtom(Bottom.initialMainLeftAtom);
    const [ animateMainLeft, setAnimateMainLeft ] = useAtom(Bottom.animateMainLeftAtom);
    const [ initialMainOpacity, setInitialMainOpacity ] = useAtom(Bottom.initialMainOpacityAtom);
    const [ animateMainOpacity, setAnimateMainOpacity ] = useAtom(Bottom.animateMainOpacityAtom);
    const [ initialMainScale, setInitialMainScale ] = useAtom(Bottom.initialMainScaleAtom);
    const [ animateMainScale, setAnimateMainScale ] = useAtom(Bottom.animateMainScaleAtom);
    const [ initialMintBottom, setInitialMintBottom ] = useAtom(Bottom.initialMintBottomAtom);
    const [ animateMintBottom, setAnimateMintBottom ] = useAtom(Bottom.animateMintBottomAtom);
    const [ initialMintLeft, setInitialMintLeft ] = useAtom(Bottom.initialMintLeftAtom);
    const [ animateMintLeft, setAnimateMintLeft ] = useAtom(Bottom.animateMintLeftAtom);
    const [ initialMintOpacity, setInitialMintOpacity ] = useAtom(Bottom.initialMintOpacityAtom);
    const [ animateMintOpacity, setAnimateMintOpacity ] = useAtom(Bottom.animateMintOpacityAtom);
    const [ initialMintScale, setInitialMintScale ] = useAtom(Bottom.initialMintScaleAtom);
    const [ animateMintScale, setAnimateMintScale ] = useAtom(Bottom.animateMintScaleAtom);
    const [ initialTimelineBottom, setInitialTimelineBottom ] = useAtom(Bottom.initialTimelineBottomAtom);
    const [ animateTimelineBottom, setAnimateTimelineBottom ] = useAtom(Bottom.animateTimelineBottomAtom);
    const [ initialTimelineLeft, setInitialTimelineLeft ] = useAtom(Bottom.initialTimelineLeftAtom);
    const [ animateTimelineLeft, setAnimateTimelineLeft ] = useAtom(Bottom.animateTimelineLeftAtom);
    const [ initialTimelineOpacity, setInitialTimelineOpacity ] = useAtom(Bottom.initialTimelineOpacityAtom);
    const [ animateTimelineOpacity, setAnimateTimelineOpacity ] = useAtom(Bottom.animateTimelineOpacityAtom);
    const [ initialTimelineScale, setInitialTimelineScale ] = useAtom(Bottom.initialTimelineScaleAtom);
    const [ animateTimelineScale, setAnimateTimelineScale ] = useAtom(Bottom.animateTimelineScaleAtom);
    const [ initialAboutBottom, setInitialAboutBottom ] = useAtom(Bottom.initialAboutBottomAtom);
    const [ animateAboutBottom, setAnimateAboutBottom ] = useAtom(Bottom.animateAboutBottomAtom);
    const [ initialAboutLeft, setInitialAboutLeft ] = useAtom(Bottom.initialAboutLeftAtom);
    const [ animateAboutLeft, setAnimateAboutLeft ] = useAtom(Bottom.animateAboutLeftAtom);
    const [ initialAboutOpacity, setInitialAboutOpacity ] = useAtom(Bottom.initialAboutOpacityAtom);
    const [ animateAboutOpacity, setAnimateAboutOpacity ] = useAtom(Bottom.animateAboutOpacityAtom);
    const [ initialAboutScale, setInitialAboutScale ] = useAtom(Bottom.initialAboutScaleAtom);
    const [ animateAboutScale, setAnimateAboutScale ] = useAtom(Bottom.animateAboutScaleAtom);
    const [ initialFoundersBottom, setInitialFoundersBottom ] = useAtom(Bottom.initialFoundersBottomAtom);
    const [ animateFoundersBottom, setAnimateFoundersBottom ] = useAtom(Bottom.animateFoundersBottomAtom);
    const [ initialFoundersLeft, setInitialFoundersLeft ] = useAtom(Bottom.initialFoundersLeftAtom);
    const [ animateFoundersLeft, setAnimateFoundersLeft ] = useAtom(Bottom.animateFoundersLeftAtom);
    const [ initialFoundersOpacity, setInitialFoundersOpacity ] = useAtom(Bottom.initialFoundersOpacityAtom);
    const [ animateFoundersOpacity, setAnimateFoundersOpacity ] = useAtom(Bottom.animateFoundersOpacityAtom);
    const [ initialFoundersScale, setInitialFoundersScale ] = useAtom(Bottom.initialFoundersScaleAtom);
    const [ animateFoundersScale, setAnimateFoundersScale ] = useAtom(Bottom.animateFoundersScaleAtom);
    const [ initialVisionBottom, setInitialVisionBottom ] = useAtom(Bottom.initialVisionBottomAtom);
    const [ animateVisionBottom, setAnimateVisionBottom ] = useAtom(Bottom.animateVisionBottomAtom);
    const [ initialVisionLeft, setInitialVisionLeft ] = useAtom(Bottom.initialVisionLeftAtom);
    const [ animateVisionLeft, setAnimateVisionLeft ] = useAtom(Bottom.animateVisionLeftAtom);
    const [ initialVisionOpacity, setInitialVisionOpacity ] = useAtom(Bottom.initialVisionOpacityAtom);
    const [ animateVisionOpacity, setAnimateVisionOpacity ] = useAtom(Bottom.animateVisionOpacityAtom);
    const [ initialVisionScale, setInitialVisionScale ] = useAtom(Bottom.initialVisionScaleAtom);
    const [ animateVisionScale, setAnimateVisionScale ] = useAtom(Bottom.animateVisionScaleAtom);
    const [ initialTokenomicsBottom, setInitialTokenomicsBottom ] = useAtom(Bottom.initialTokenomicsBottomAtom);
    const [ animateTokenomicsBottom, setAnimateTokenomicsBottom ] = useAtom(Bottom.animateTokenomicsBottomAtom);
    const [ initialTokenomicsLeft, setInitialTokenomicsLeft ] = useAtom(Bottom.initialTokenomicsLeftAtom);
    const [ animateTokenomicsLeft, setAnimateTokenomicsLeft ] = useAtom(Bottom.animateTokenomicsLeftAtom);
    const [ initialTokenomicsOpacity, setInitialTokenomicsOpacity ] = useAtom(Bottom.initialTokenomicsOpacityAtom);
    const [ animateTokenomicsOpacity, setAnimateTokenomicsOpacity ] = useAtom(Bottom.animateTokenomicsOpacityAtom);
    const [ initialTokenomicsScale, setInitialTokenomicsScale ] = useAtom(Bottom.initialTokenomicsScaleAtom);
    const [ animateTokenomicsScale, setAnimateTokenomicsScale ] = useAtom(Bottom.animateTokenomicsScaleAtom);

    const [ initialTitleTop, setInitialTitleTop ] = useAtom(Bottom.initialTitleTopAtom);
    const [ animateTitleTop, setAnimateTitleTop ] = useAtom(Bottom.animateTitleTopAtom);
    const [ initialTitleLeft, setInitialTitleLeft ] = useAtom(Bottom.initialTitleLeftAtom);
    const [ animateTitleLeft, setAnimateTitleLeft ] = useAtom(Bottom.animateTitleLeftAtom);
    const [ initialTitleOpacity, setInitialTitleOpacity ] = useAtom(Bottom.initialTitleOpacityAtom);
    const [ animateTitleOpacity, setAnimateTitleOpacity ] = useAtom(Bottom.animateTitleOpacityAtom);
    const [ initialTitleScale, setInitialTitleScale ] = useAtom(Bottom.initialTitleScaleAtom);
    const [ animateTitleScale, setAnimateTitleScale ] = useAtom(Bottom.animateTitleScaleAtom);

    useEffect(() => {
        setSkyColor('#288fe3');
        setInitialBGOpacity(animateBGOpacity)
        setAnimateBGOpacity(0)
    })

    useEffect(() => {
        setInitialStarsTop(animateStarsTop);
        setInitialStarsLeft(animateStarsLeft);
        setInitialStarsOpacity(animateStarsOpacity);
        setAnimateStarsTop('0px');
        setAnimateStarsLeft('0px');
        setAnimateStarsOpacity(1);
        setInitialSunTop(animateSunTop);
        setInitialSunLeft(animateSunLeft);
        setAnimateSunTop('-20rem');
        setAnimateSunLeft('-20rem');
    })

    useEffect(() => {
        setInitialTitleTop(animateTitleTop)
        setInitialTitleLeft(animateTitleLeft)
        setInitialTitleOpacity(animateTitleOpacity)
        setInitialTitleScale(animateTitleScale)

        setInitialMainBottom(animateMainBottom)
        setInitialMainLeft(animateMainLeft)
        setInitialMainOpacity(animateMainOpacity)
        setInitialMainScale(animateMainScale)
        setInitialMintBottom(animateMintBottom)
        setInitialMintLeft(animateMintLeft)
        setInitialMintOpacity(animateMintOpacity)
        setInitialMintScale(animateMintScale)
        setInitialTimelineBottom(animateTimelineBottom)
        setInitialTimelineLeft(animateTimelineLeft)
        setInitialTimelineOpacity(animateTimelineOpacity)
        setInitialTimelineScale(animateTimelineScale)
        setInitialAboutBottom(animateAboutBottom)
        setInitialAboutLeft(animateAboutLeft)
        setInitialAboutOpacity(animateAboutOpacity)
        setInitialAboutScale(animateAboutScale)
        setInitialFoundersBottom(animateFoundersBottom)
        setInitialFoundersLeft(animateFoundersLeft)
        setInitialFoundersOpacity(animateFoundersOpacity)
        setInitialFoundersScale(animateFoundersScale)
        setInitialVisionBottom(animateVisionBottom)
        setInitialVisionLeft(animateVisionLeft)
        setInitialVisionOpacity(animateVisionOpacity)
        setInitialVisionScale(animateVisionScale)
        setInitialTokenomicsBottom(animateTokenomicsBottom)
        setInitialTokenomicsLeft(animateTokenomicsLeft)
        setInitialTokenomicsOpacity(animateTokenomicsOpacity)
        setInitialTokenomicsScale(animateTokenomicsScale)

        setAnimateTitleTop('0vh')
        setAnimateTitleLeft('unset')
        setAnimateTitleOpacity(1)
        setAnimateTitleScale('scale(1)')

        setAnimateMainBottom('-20vh')
        setAnimateMainLeft('-20vw')
        setAnimateMainOpacity(0)
        setAnimateMainScale('scale(1.5)')
        setAnimateMintBottom('-0vh')
        setAnimateMintLeft('-0vw')
        setAnimateMintOpacity(0)
        setAnimateMintScale('scale(1)')
        setAnimateTimelineBottom('-0vh')
        setAnimateTimelineLeft('-0vw')
        setAnimateTimelineOpacity(0)
        setAnimateTimelineScale('scale(1)')
        setAnimateAboutBottom('-0vh')
        setAnimateAboutLeft('-0vw')
        setAnimateAboutOpacity(0)
        setAnimateAboutScale('scale(1)')
        setAnimateFoundersBottom('-0vh')
        setAnimateFoundersLeft('-0vw')
        setAnimateFoundersOpacity(0)
        setAnimateFoundersScale('scale(1)')
        setAnimateVisionBottom('-0vh')
        setAnimateVisionLeft('-0vw')
        setAnimateVisionOpacity(0)
        setAnimateVisionScale('scale(1)')
        setAnimateTokenomicsBottom('-0vh')
        setAnimateTokenomicsLeft('-0vw')
        setAnimateTokenomicsOpacity(0)
        setAnimateTokenomicsScale('scale()')
    })

    return (

        <div className='RouteWrapper BuddhaBoysWrapper'>

        {/* ### SKY BACKGROUND ### */}

            <Top.TopBackground
                skyColorAtom={skyColor}
                initialBGOpacityAtom={initialBGOpacity}
                animateBGOpacityAtom={animateBGOpacity}
                initialStarsTopAtom={initialStarsTop}
                animateStarsTopAtom={animateStarsTop}
                initialStarsLeftAtom={initialStarsLeft}
                animateStarsLeftAtom={animateStarsLeft}
                initialStarsOpacityAtom={initialStarsOpacity}
                animateStarsOpacityAtom={animateStarsOpacity}
                initialSunTopAtom={initialSunTop}
                animateSunTopAtom={animateSunTop}
                initialSunLeftAtom={initialSunLeft}
                animateSunLeftAtom={animateSunLeft}
            />

            <div className='Base BuddhaBoys'>

        {/* ##### VIEWPORT */}

                <div className='viewport'>

                    <div className='siteMap flx'>
                        <div className='breadcrumbs'>
                            <Link to='/home'>MCAA</Link> &nbsp; &#62; &nbsp; <Link className='' to='/buddhaboys'>Gallery</Link>
                        </div>
                        <div className='next-bread'>
                            <Link to='/mint'>MINT</Link>
                        </div>
                    </div>

                {/* ### CARD ### */}

                    <motion.div
                        className='container'
                        style={{ overflow: 'scroll' }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ type: 'tween', duration: 0.4 }}
                    >

                        <div>

                            <h3>Gallery</h3>
                            <p>Founder Token: </p>

                            <NF></NF>

                            <br />

                            <p>Funder Associate / Community:</p>
                            <SF></SF>

                            <br />

                            <p>Sponsor / Participant Token:</p>
                            <TF></TF>

                            <br />

                        </div>

                    </motion.div>

                {/* ### BOTTOM BACKGROUND ### */}

                    <Bottom.BottomBackground
                        initialMainBottomAtom={initialMainBottom}
                        animateMainBottomAtom={animateMainBottom}
                        initialMainLeftAtom={initialMainLeft}
                        animateMainLeftAtom={animateMainLeft}
                        initialMainOpacityAtom={initialMainOpacity}
                        animateMainOpacityAtom={animateMainOpacity}
                        initialMainScaleAtom={initialMainScale}
                        animateMainScaleAtom={animateMainScale}
                        initialMintBottomAtom={initialMintBottom}
                        animateMintBottomAtom={animateMintBottom}
                        initialMintLeftAtom={initialMintLeft}
                        animateMintLeftAtom={animateMintLeft}
                        initialMintOpacityAtom={initialMintOpacity}
                        animateMintOpacityAtom={animateMintOpacity}
                        initialMintScaleAtom={initialMintScale}
                        animateMintScaleAtom={animateMintScale}
                        initialTimelineBottomAtom={initialTimelineBottom}
                        animateTimelineBottomAtom={animateTimelineBottom}
                        initialTimelineLeftAtom={initialTimelineLeft}
                        animateTimelineLeftAtom={animateTimelineLeft}
                        initialTimelineOpacityAtom={initialTimelineOpacity}
                        animateTimelineOpacityAtom={animateTimelineOpacity}
                        initialTimelineScaleAtom={initialTimelineScale}
                        animateTimelineScaleAtom={animateTimelineScale}
                        initialAboutBottomAtom={initialAboutBottom}
                        animateAboutBottomAtom={animateAboutBottom}
                        initialAboutLeftAtom={initialAboutLeft}
                        animateAboutLeftAtom={animateAboutLeft}
                        initialAboutOpacityAtom={initialAboutOpacity}
                        animateAboutOpacityAtom={animateAboutOpacity}
                        initialAboutScaleAtom={initialAboutScale}
                        animateAboutScaleAtom={animateAboutScale}
                        initialFoundersBottomAtom={initialFoundersBottom}
                        animateFoundersBottomAtom={animateFoundersBottom}
                        initialFoundersLeftAtom={initialFoundersLeft}
                        animateFoundersLeftAtom={animateFoundersLeft}
                        initialFoundersOpacityAtom={initialFoundersOpacity}
                        animateFoundersOpacityAtom={animateFoundersOpacity}
                        initialFoundersScaleAtom={initialFoundersScale}
                        animateFoundersScaleAtom={animateFoundersScale}
                        initialVisionBottomAtom={initialVisionBottom}
                        animateVisionBottomAtom={animateVisionBottom}
                        initialVisionLeftAtom={initialVisionLeft}
                        animateVisionLeftAtom={animateVisionLeft}
                        initialVisionOpacityAtom={initialVisionOpacity}
                        animateVisionOpacityAtom={animateVisionOpacity}
                        initialVisionScaleAtom={initialVisionScale}
                        animateVisionScaleAtom={animateVisionScale}
                        initialTokenomicsBottomAtom={initialTokenomicsBottom}
                        animateTokenomicsBottomAtom={animateTokenomicsBottom}
                        initialTokenomicsLeftAtom={initialTokenomicsLeft}
                        animateTokenomicsLeftAtom={animateTokenomicsLeft}
                        initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
                        animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
                        initialTokenomicsScaleAtom={initialTokenomicsScale}
                        animateTokenomicsScaleAtom={animateTokenomicsScale}
                        initialTitleTopAtom={initialTitleTop}
                        animateTitleTopAtom={animateTitleTop}
                        initialTitleLeftAtom={initialTitleLeft}
                        animateTitleLeftAtom={animateTitleLeft}
                        initialTitleOpacityAtom={initialTitleOpacity}
                        animateTitleOpacityAtom={animateTitleOpacity}
                        initialTitleScaleAtom={initialTitleScale}
                        animateTitleScaleAtom={animateTitleScale}
                    />

                </div>

            </div>

        </div>
    );
}

export default BuddhaBoys