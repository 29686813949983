import React from 'react';
import p004 from 'assets/preview/004.jpg';
import p011 from 'assets/preview/011.jpg';
import p015 from 'assets/preview/015.jpg';
import p028 from 'assets/preview/028.jpg';
import p036 from 'assets/preview/036.jpg';
import p041 from 'assets/preview/041.jpg';
import p072 from 'assets/preview/072.jpg';
import p075 from 'assets/preview/075.jpg';
import p076 from 'assets/preview/076.jpg';

function Files() {

    return (
        <div class="gallery">
            <img className='responsive' src={p075}  width='500' height='500' alt='075' />
            <img className='responsive' src={p028}  width='500' height='500' alt='028' />
            <img className='responsive' src={p036}  width='500' height='500' alt='036' />
            <img className='responsive' src={p004}  width='500' height='500' alt='004' />           
            <img className='responsive' src={p011}  width='500' height='500' alt='011' />
            <img className='responsive' src={p015}  width='500' height='500' alt='015' />            
            <img className='responsive' src={p041}  width='500' height='500' alt='041' />
            <img className='responsive' src={p072}  width='500' height='500' alt='072' />
            <img className='responsive' src={p076}  width='500' height='500' alt='076' />
        </div>
    );
}

export default Files;