import { motion } from 'framer-motion';
import { atom } from 'jotai';
import Stars from 'components/vectors/Stars';

export const classMintingAtom = atom('idle');
export const skyColorAtom = atom('#001850');
export const initialBGOpacityAtom = atom(0)
export const animateBGOpacityAtom = atom(0)

export const initialStarsTopAtom = atom('0px')
export const animateStarsTopAtom = atom('0px')
export const initialStarsLeftAtom = atom('0px')
export const animateStarsLeftAtom = atom('0px')
export const initialStarsOpacityAtom = atom(1)
export const animateStarsOpacityAtom = atom(1)

export const initialSunLeftAtom = atom('0rem')
export const animateSunLeftAtom = atom('0rem')
export const initialSunTopAtom = atom('0rem')
export const animateSunTopAtom = atom('0rem')

export const TopBackground = ({ 
    classMintingAtom,
    skyColorAtom,
    initialBGOpacityAtom,
    animateBGOpacityAtom,
    initialStarsTopAtom,
    animateStarsTopAtom,
    initialStarsLeftAtom,
    animateStarsLeftAtom,
    initialStarsOpacityAtom,
    animateStarsOpacityAtom,
    initialSunLeftAtom,
    animateSunLeftAtom,
    animateSunTopAtom,
    initialSunTopAtom
}) => {

    return (

        <motion.div
            className={`TopContainer ${classMintingAtom}`}
        >

            <div style={{ backgroundColor: skyColorAtom }} className='bg'></div>

            <motion.div
                className={`thisStars ${classMintingAtom}`}
                initial={{ top: initialStarsTopAtom, left: initialStarsLeftAtom, opacity: initialStarsOpacityAtom }}
                animate={{ top: animateStarsTopAtom, left: animateStarsLeftAtom, opacity: animateStarsOpacityAtom }}
                transition={{ type: 'tween', duration: 1 }}
            >

                <Stars />

            </motion.div>

            <motion.img
                className={`thisSun ${classMintingAtom}`}
                initial={{ top: initialSunTopAtom, left: initialSunLeftAtom }}
                animate={{ top: animateSunTopAtom, left: animateSunLeftAtom }}
                transition={{ type: 'tween', duration: 1 }}
                src='/sun.png'
                alt='Sun'
                width='300'
                height='300'
            />

        </motion.div>
    );
};
