/* global BigInt */
import React, { useState, useEffect } from "react";
import ConnectInline from "components/ConnectInline";
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import FlipCard, { BackCard, FrontCard } from "components/FlipNFT";
import contractInterface from "contracts/MCAAxNFT.json";
import Minting from "components/vectors/Minting";
import * as Top from "components/backgrounds/TopBackground";
import * as Bottom from "components/backgrounds/BottomBackground";
import { ReactComponent as Etherscan } from "assets/etherscan.svg";
import { ReactComponent as Opensea } from "assets/opensea.svg";
import { ReactComponent as Rarible } from "assets/rarible.svg";

// const contractConfig = {
//     addressOrName: '0xC4ec0b04ECeBDBEB5110D928DE22E245A336c9d0',
//     contractInterface: contractInterface
// };

const Mint = () => {
  const { isConnected } = useAccount();
  const [numberOfTokens, setNumberOfTokens] = useState("1");
  const [tokenID, setTokenID] = useState("0");
  const [payable, setPayable] = useState(BigInt(120000000000000000).toString());

  const [classMinting, setClassMinting] = useAtom(Top.classMintingAtom);

  const { config } = usePrepareContractWrite({
    address: process.env.REACT_APP_NFT_CONTRACT_ADDRESS_GOERLI,
    abi: contractInterface,
    functionName: "mintPublicSale",
    overrides: {
      value: payable,
    },
    args: [numberOfTokens],
  });

  const {
    data: mintData,
    isLoading: isMintLoading,
    isSuccess: isMintStarted,
    error: mintError,
    write,
  } = useContractWrite(config);

  const { isSuccess: txSuccess, error: txError } = useWaitForTransaction({
    hash: mintData?.hash,
    wait: mintData?.wait,
    onSuccess(data) {
      setTokenID(Number(data.logs[0].topics[3]));
    },
  });

  useEffect(() => {
    setClassMinting("minting");

    if (mintError) {
      setClassMinting("idle");
    }

    if (txSuccess) {
      setClassMinting("success");
    }
  }, [setClassMinting, setPayable, setNumberOfTokens, mintError, txSuccess]);

  const isMinted = txSuccess;

  // const [ totalMinted, setTotalMinted ] = useState(0);

  // const {
  //     data: mintData,
  //     write: mint,
  //     isLoading: isMintLoading,
  //     isSuccess: isMintStarted,
  //     error: mintError,
  // } = useContractWrite({ ...contractConfig, functionName: 'mint' });

  // const { data: totalSupplyData } = useContractRead({
  //     ...contractConfig,
  //     functionName: 'totalSupply',
  //     watch: true,
  // });

  // const { isSuccess: txSuccess, error: txError } = useWaitForTransaction({
  //     hash: mintData?.hash,
  // });

  // useEffect(() => {
  //     if (totalSupplyData) {
  //         setTotalMinted(totalSupplyData.toNumber());
  //     }
  //     if (!isMintLoading) {

  //     }
  //     if (mintError) {
  //         setClassTop('idle')
  //     }
  //     if (txSuccess) {
  //         setClassTop('success')
  //     }
  // }, [isMintLoading, txSuccess]);

  // const [ classMinting, setClassTop ] = useAtom(Top.classMintingAtom);
  const [skyColor, setSkyColor] = useAtom(Top.skyColorAtom);
  const [initialBGOpacity, setInitialBGOpacity] = useAtom(
    Top.initialBGOpacityAtom
  );
  const [animateBGOpacity, setAnimateBGOpacity] = useAtom(
    Top.animateBGOpacityAtom
  );
  const [initialStarsTop, setInitialStarsTop] = useAtom(
    Top.initialStarsTopAtom
  );
  const [animateStarsTop, setAnimateStarsTop] = useAtom(
    Top.animateStarsTopAtom
  );
  const [initialStarsLeft, setInitialStarsLeft] = useAtom(
    Top.initialStarsLeftAtom
  );
  const [animateStarsLeft, setAnimateStarsLeft] = useAtom(
    Top.animateStarsLeftAtom
  );
  const [initialStarsOpacity, setInitialStarsOpacity] = useAtom(
    Top.initialStarsOpacityAtom
  );
  const [animateStarsOpacity, setAnimateStarsOpacity] = useAtom(
    Top.animateStarsOpacityAtom
  );
  const [initialSunTop, setInitialSunTop] = useAtom(Top.initialSunTopAtom);
  const [animateSunTop, setAnimateSunTop] = useAtom(Top.animateSunTopAtom);
  const [initialSunLeft, setInitialSunLeft] = useAtom(Top.initialSunLeftAtom);
  const [animateSunLeft, setAnimateSunLeft] = useAtom(Top.animateSunLeftAtom);

  const [initialMainBottom, setInitialMainBottom] = useAtom(
    Bottom.initialMainBottomAtom
  );
  const [animateMainBottom, setAnimateMainBottom] = useAtom(
    Bottom.animateMainBottomAtom
  );
  const [initialMainLeft, setInitialMainLeft] = useAtom(
    Bottom.initialMainLeftAtom
  );
  const [animateMainLeft, setAnimateMainLeft] = useAtom(
    Bottom.animateMainLeftAtom
  );
  const [initialMainOpacity, setInitialMainOpacity] = useAtom(
    Bottom.initialMainOpacityAtom
  );
  const [animateMainOpacity, setAnimateMainOpacity] = useAtom(
    Bottom.animateMainOpacityAtom
  );
  const [initialMainScale, setInitialMainScale] = useAtom(
    Bottom.initialMainScaleAtom
  );
  const [animateMainScale, setAnimateMainScale] = useAtom(
    Bottom.animateMainScaleAtom
  );
  const [initialMintBottom, setInitialMintBottom] = useAtom(
    Bottom.initialMintBottomAtom
  );
  const [animateMintBottom, setAnimateMintBottom] = useAtom(
    Bottom.animateMintBottomAtom
  );
  const [initialMintLeft, setInitialMintLeft] = useAtom(
    Bottom.initialMintLeftAtom
  );
  const [animateMintLeft, setAnimateMintLeft] = useAtom(
    Bottom.animateMintLeftAtom
  );
  const [initialMintOpacity, setInitialMintOpacity] = useAtom(
    Bottom.initialMintOpacityAtom
  );
  const [animateMintOpacity, setAnimateMintOpacity] = useAtom(
    Bottom.animateMintOpacityAtom
  );
  const [initialMintScale, setInitialMintScale] = useAtom(
    Bottom.initialMintScaleAtom
  );
  const [animateMintScale, setAnimateMintScale] = useAtom(
    Bottom.animateMintScaleAtom
  );
  const [initialTimelineBottom, setInitialTimelineBottom] = useAtom(
    Bottom.initialTimelineBottomAtom
  );
  const [animateTimelineBottom, setAnimateTimelineBottom] = useAtom(
    Bottom.animateTimelineBottomAtom
  );
  const [initialTimelineLeft, setInitialTimelineLeft] = useAtom(
    Bottom.initialTimelineLeftAtom
  );
  const [animateTimelineLeft, setAnimateTimelineLeft] = useAtom(
    Bottom.animateTimelineLeftAtom
  );
  const [initialTimelineOpacity, setInitialTimelineOpacity] = useAtom(
    Bottom.initialTimelineOpacityAtom
  );
  const [animateTimelineOpacity, setAnimateTimelineOpacity] = useAtom(
    Bottom.animateTimelineOpacityAtom
  );
  const [initialTimelineScale, setInitialTimelineScale] = useAtom(
    Bottom.initialTimelineScaleAtom
  );
  const [animateTimelineScale, setAnimateTimelineScale] = useAtom(
    Bottom.animateTimelineScaleAtom
  );
  const [initialAboutBottom, setInitialAboutBottom] = useAtom(
    Bottom.initialAboutBottomAtom
  );
  const [animateAboutBottom, setAnimateAboutBottom] = useAtom(
    Bottom.animateAboutBottomAtom
  );
  const [initialAboutLeft, setInitialAboutLeft] = useAtom(
    Bottom.initialAboutLeftAtom
  );
  const [animateAboutLeft, setAnimateAboutLeft] = useAtom(
    Bottom.animateAboutLeftAtom
  );
  const [initialAboutOpacity, setInitialAboutOpacity] = useAtom(
    Bottom.initialAboutOpacityAtom
  );
  const [animateAboutOpacity, setAnimateAboutOpacity] = useAtom(
    Bottom.animateAboutOpacityAtom
  );
  const [initialAboutScale, setInitialAboutScale] = useAtom(
    Bottom.initialAboutScaleAtom
  );
  const [animateAboutScale, setAnimateAboutScale] = useAtom(
    Bottom.animateAboutScaleAtom
  );
  const [initialFoundersBottom, setInitialFoundersBottom] = useAtom(
    Bottom.initialFoundersBottomAtom
  );
  const [animateFoundersBottom, setAnimateFoundersBottom] = useAtom(
    Bottom.animateFoundersBottomAtom
  );
  const [initialFoundersLeft, setInitialFoundersLeft] = useAtom(
    Bottom.initialFoundersLeftAtom
  );
  const [animateFoundersLeft, setAnimateFoundersLeft] = useAtom(
    Bottom.animateFoundersLeftAtom
  );
  const [initialFoundersOpacity, setInitialFoundersOpacity] = useAtom(
    Bottom.initialFoundersOpacityAtom
  );
  const [animateFoundersOpacity, setAnimateFoundersOpacity] = useAtom(
    Bottom.animateFoundersOpacityAtom
  );
  const [initialFoundersScale, setInitialFoundersScale] = useAtom(
    Bottom.initialFoundersScaleAtom
  );
  const [animateFoundersScale, setAnimateFoundersScale] = useAtom(
    Bottom.animateFoundersScaleAtom
  );
  const [initialVisionBottom, setInitialVisionBottom] = useAtom(
    Bottom.initialVisionBottomAtom
  );
  const [animateVisionBottom, setAnimateVisionBottom] = useAtom(
    Bottom.animateVisionBottomAtom
  );
  const [initialVisionLeft, setInitialVisionLeft] = useAtom(
    Bottom.initialVisionLeftAtom
  );
  const [animateVisionLeft, setAnimateVisionLeft] = useAtom(
    Bottom.animateVisionLeftAtom
  );
  const [initialVisionOpacity, setInitialVisionOpacity] = useAtom(
    Bottom.initialVisionOpacityAtom
  );
  const [animateVisionOpacity, setAnimateVisionOpacity] = useAtom(
    Bottom.animateVisionOpacityAtom
  );
  const [initialVisionScale, setInitialVisionScale] = useAtom(
    Bottom.initialVisionScaleAtom
  );
  const [animateVisionScale, setAnimateVisionScale] = useAtom(
    Bottom.animateVisionScaleAtom
  );
  const [initialTokenomicsBottom, setInitialTokenomicsBottom] = useAtom(
    Bottom.initialTokenomicsBottomAtom
  );
  const [animateTokenomicsBottom, setAnimateTokenomicsBottom] = useAtom(
    Bottom.animateTokenomicsBottomAtom
  );
  const [initialTokenomicsLeft, setInitialTokenomicsLeft] = useAtom(
    Bottom.initialTokenomicsLeftAtom
  );
  const [animateTokenomicsLeft, setAnimateTokenomicsLeft] = useAtom(
    Bottom.animateTokenomicsLeftAtom
  );
  const [initialTokenomicsOpacity, setInitialTokenomicsOpacity] = useAtom(
    Bottom.initialTokenomicsOpacityAtom
  );
  const [animateTokenomicsOpacity, setAnimateTokenomicsOpacity] = useAtom(
    Bottom.animateTokenomicsOpacityAtom
  );
  const [initialTokenomicsScale, setInitialTokenomicsScale] = useAtom(
    Bottom.initialTokenomicsScaleAtom
  );
  const [animateTokenomicsScale, setAnimateTokenomicsScale] = useAtom(
    Bottom.animateTokenomicsScaleAtom
  );

  const [initialTitleTop, setInitialTitleTop] = useAtom(
    Bottom.initialTitleTopAtom
  );
  const [animateTitleTop, setAnimateTitleTop] = useAtom(
    Bottom.animateTitleTopAtom
  );
  const [initialTitleLeft, setInitialTitleLeft] = useAtom(
    Bottom.initialTitleLeftAtom
  );
  const [animateTitleLeft, setAnimateTitleLeft] = useAtom(
    Bottom.animateTitleLeftAtom
  );
  const [initialTitleOpacity, setInitialTitleOpacity] = useAtom(
    Bottom.initialTitleOpacityAtom
  );
  const [animateTitleOpacity, setAnimateTitleOpacity] = useAtom(
    Bottom.animateTitleOpacityAtom
  );
  const [initialTitleScale, setInitialTitleScale] = useAtom(
    Bottom.initialTitleScaleAtom
  );
  const [animateTitleScale, setAnimateTitleScale] = useAtom(
    Bottom.animateTitleScaleAtom
  );

  useEffect(() => {
    setSkyColor("#288fe3");
    setInitialBGOpacity(animateBGOpacity);
    setAnimateBGOpacity(1);
  });

  useEffect(() => {
    setInitialStarsTop(animateStarsTop);
    setInitialStarsLeft(animateStarsLeft);
    setInitialStarsOpacity(animateStarsOpacity);
    setAnimateStarsTop("0px");
    setAnimateStarsLeft("0px");
    setAnimateStarsOpacity(1);
    setInitialSunTop(animateSunTop);
    setInitialSunLeft(animateSunLeft);
    setAnimateSunTop("15rem");
    setAnimateSunLeft("16rem");
  });

  useEffect(() => {
    setInitialTitleTop(animateTitleTop);
    setInitialTitleLeft(animateTitleLeft);
    setInitialTitleOpacity(animateTitleOpacity);
    setInitialTitleScale(animateTitleScale);

    setInitialMainBottom(animateMainBottom);
    setInitialMainLeft(animateMainLeft);
    setInitialMainOpacity(animateMainOpacity);
    setInitialMainScale(animateMainScale);
    setInitialMintBottom(animateMintBottom);
    setInitialMintLeft(animateMintLeft);
    setInitialMintOpacity(animateMintOpacity);
    setInitialMintScale(animateMintScale);
    setInitialTimelineBottom(animateTimelineBottom);
    setInitialTimelineLeft(animateTimelineLeft);
    setInitialTimelineOpacity(animateTimelineOpacity);
    setInitialTimelineScale(animateTimelineScale);
    setInitialAboutBottom(animateAboutBottom);
    setInitialAboutLeft(animateAboutLeft);
    setInitialAboutOpacity(animateAboutOpacity);
    setInitialAboutScale(animateAboutScale);
    setInitialFoundersBottom(animateFoundersBottom);
    setInitialFoundersLeft(animateFoundersLeft);
    setInitialFoundersOpacity(animateFoundersOpacity);
    setInitialFoundersScale(animateFoundersScale);
    setInitialVisionBottom(animateVisionBottom);
    setInitialVisionLeft(animateVisionLeft);
    setInitialVisionOpacity(animateVisionOpacity);
    setInitialVisionScale(animateVisionScale);
    setInitialTokenomicsBottom(animateTokenomicsBottom);
    setInitialTokenomicsLeft(animateTokenomicsLeft);
    setInitialTokenomicsOpacity(animateTokenomicsOpacity);
    setInitialTokenomicsScale(animateTokenomicsScale);

    setAnimateTitleTop("70vh");
    setAnimateTitleLeft("unset");
    setAnimateTitleOpacity(1);
    setAnimateTitleScale("scale(0.75)");

    setAnimateMainBottom("-0vh");
    setAnimateMainLeft("-0vw");
    setAnimateMainOpacity(0);
    setAnimateMainScale("scale(1)");
    setAnimateMintBottom("-0vh");
    setAnimateMintLeft("-0vw");
    setAnimateMintOpacity(1);
    setAnimateMintScale("scale(1)");
    setAnimateTimelineBottom("-0vh");
    setAnimateTimelineLeft("-0vw");
    setAnimateTimelineOpacity(0);
    setAnimateTimelineScale("scale(1)");
    setAnimateAboutBottom("-0vh");
    setAnimateAboutLeft("-0vw");
    setAnimateAboutOpacity(0);
    setAnimateAboutScale("scale(1)");
    setAnimateFoundersBottom("-0vh");
    setAnimateFoundersLeft("-0vw");
    setAnimateFoundersOpacity(0);
    setAnimateFoundersScale("scale(1)");
    setAnimateVisionBottom("-0vh");
    setAnimateVisionLeft("-0vw");
    setAnimateVisionOpacity(0);
    setAnimateVisionScale("scale(1)");
    setAnimateTokenomicsBottom("-0vh");
    setAnimateTokenomicsLeft("-0vw");
    setAnimateTokenomicsOpacity(0);
    setAnimateTokenomicsScale("scale(1)");
  });

  // const trigger=()=>{
  //     setClassMinting('minting')
  //     mint();
  // }

  return (
    <div className="RouteWrapper MintWrapper">
      <div className="overlay"></div>

      {/* ### BACKGROUND ANIMATION WHILE MINTING ### */}

      <div className={`psych ${classMinting}`}>
        <Minting />
      </div>

      {/* ### SKY BACKGROUND ### */}

      <Top.TopBackground
        classMintingAtom={classMinting}
        skyColorAtom={skyColor}
        initialBGOpacityAtom={initialBGOpacity}
        animateBGOpacityAtom={animateBGOpacity}
        initialStarsTopAtom={initialStarsTop}
        animateStarsTopAtom={animateStarsTop}
        initialStarsLeftAtom={initialStarsLeft}
        animateStarsLeftAtom={animateStarsLeft}
        initialStarsOpacityAtom={initialStarsOpacity}
        animateStarsOpacityAtom={animateStarsOpacity}
        initialSunTopAtom={initialSunTop}
        animateSunTopAtom={animateSunTop}
        initialSunLeftAtom={initialSunLeft}
        animateSunLeftAtom={animateSunLeft}
      />

      <div className={`Base ${classMinting}`}>
        <div className="viewport">
          {/* ### CARD ### */}

          <motion.div
            className={`container Mint ${classMinting}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "tween", duration: 0.4 }}
          >
            {/* ### LEFT CARD ### */}

            <div
              style={{ flex: "1 1 auto" }}
              className={`Mint ${classMinting}`}
            >
              <div style={{ padding: "24px 24px 24px 24px" }}>
                <h1>MCAA Bud Dah Boy</h1>

                {mintError && (
                  <p style={{ marginTop: 24, color: "#FF6257" }}>
                    d Error: {mintError.message}
                  </p>
                )}

                {txError && (
                  <p style={{ marginTop: 24, color: "#FF6257" }}>
                    Error: {txError.message}
                  </p>
                )}

                {isConnected && !isMinted && (
                  // <button
                  //     style={{ marginTop: 24 }}
                  //     disabled={isMintLoading || isMintStarted}
                  //     className={`button lg mint ${classMinting}`}
                  //     data-mint-loading={isMintLoading}
                  //     data-mint-started={isMintStarted}
                  //     onClick={() => trigger()}

                  // >
                  //     {isMintLoading && 'Waiting for approval'}
                  //     {isMintStarted && 'Minting...'}
                  //     {!isMintLoading && !isMintStarted && 'Mint NFT'}
                  // </button>
                  <button
                    style={{
                      margin: "24px auto 0",
                      maxWidth: "200px",
                      display: "block",
                    }}
                    disabled={isMintLoading || isMintStarted}
                    className={`btn--primary lg mint ${classMinting}`}
                    data-mint-loading={isMintLoading}
                    data-mint-started={isMintStarted}
                    onClick={() => write?.()}
                  >
                    {isMintLoading && "Confirm .."}
                    {isMintStarted && "Minting..."}
                    {!isMintLoading && !isMintStarted && "Mint NFT"}
                  </button>
                )}

                {isConnected && isMinted && (
                  // <p style={{ marginBottom: 6, position: 'relative', zIndex: 2, left: '0rem', bottom: 0, verticalAlign: 'top' }}> Find it on &nbsp;&nbsp;&nbsp;
                  //     <a href={`https://goerli.etherscan.io/tx/${mintData?.hash}`} target='_blank' rel='noreferrer'><Etherscan /> &nbsp; </a>
                  //     <a target='_blank' rel='noreferrer'  href={`https://testnets.opensea.io/assets/${mintData?.to}/1`}><Opensea /></a>
                  // </p>
                  <p
                    style={{
                      marginBottom: 6,
                      position: "relative",
                      zIndex: 2,
                      left: "0rem",
                      bottom: 0,
                      verticalAlign: "top",
                      textAlign: "center",
                    }}
                  >
                    <a
                      className="ml10 mr10"
                      href={`https://goerli.etherscan.io/tx/${mintData?.hash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Etherscan />
                    </a>
                    <a
                      className="ml10 mr10"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://testnets.opensea.io/assets/${mintData?.to}/${tokenID}`}
                    >
                      <Opensea />
                    </a>
                    <a
                      className="ml10 mr10"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://testnet.rarible.com/token/${mintData?.to}:${tokenID}`}
                    >
                      <Rarible />
                    </a>
                  </p>
                )}

                {!isConnected && (
                  <div className="MainConnect">
                    <ConnectInline />
                  </div>
                )}
              </div>
            </div>

            {/* ### RIGHT CARD ### */}

            <div
              style={{ flex: "0 0 auto" }}
              className={`NFTContainer ${classMinting}`}
            >
              <FlipCard>
                <FrontCard isCardFlipped={isMinted}>
                  <div style={{ padding: 2 }}>
                    <img
                      src="/business-boy.png"
                      width="230"
                      height="230"
                      alt="Bud Dah Boy"
                      style={{
                        borderRadius: "20rem",
                        position: "absolute",
                        maxWidth: "65vw",
                        width: "100%",
                      }}
                    />
                  </div>
                </FrontCard>

                <BackCard isCardFlipped={isMinted}>
                  <div style={{ padding: 2 }}>
                    <img
                      src="/business-boy.png"
                      width="230"
                      height="230"
                      alt="Bud Dah Boy"
                      style={{
                        borderRadius: "20rem",
                        position: "absolute",
                        maxWidth: "65vw",
                        width: "100%",
                      }}
                    />
                  </div>
                </BackCard>
              </FlipCard>
            </div>
          </motion.div>
        </div>

        {/* ### BOTTOM BACKGROUND ### */}

        {/*<motion.div className="BG transition"
                        initial={{ left: '100vw', opacity: 1, transform: 'scale(0.15)' }}
                        animate={{ left: '0', top: '-75%', opacity: 1, transform: 'scale(1)' }}
                        transition={{ type: "tween", duration: 2 }}
                    >

                        <img src="/flag.png" alt="Train" className="Train" priority="true" />

                    </motion.div>*/}

        <motion.div
          className="BG transition"
          initial={{
            zIndex: 4,
            left: "100vw",
            opacity: 1,
            transform: "scale(0.15)",
          }}
          animate={{
            zIndex: 3,
            left: "-150vw",
            opacity: 1,
            transform: "scale(1.35)",
          }}
          transition={{ type: "tween", duration: 2 }}
        >
          <img src="/train.png" alt="Train" className="Train" priority="true" />
        </motion.div>

        <Bottom.BottomBackground
          initialMainBottomAtom={initialMainBottom}
          animateMainBottomAtom={animateMainBottom}
          initialMainLeftAtom={initialMainLeft}
          animateMainLeftAtom={animateMainLeft}
          initialMainOpacityAtom={initialMainOpacity}
          animateMainOpacityAtom={animateMainOpacity}
          initialMainScaleAtom={initialMainScale}
          animateMainScaleAtom={animateMainScale}
          initialMintBottomAtom={initialMintBottom}
          animateMintBottomAtom={animateMintBottom}
          initialMintLeftAtom={initialMintLeft}
          animateMintLeftAtom={animateMintLeft}
          initialMintOpacityAtom={initialMintOpacity}
          animateMintOpacityAtom={animateMintOpacity}
          initialMintScaleAtom={initialMintScale}
          animateMintScaleAtom={animateMintScale}
          initialTimelineBottomAtom={initialTimelineBottom}
          animateTimelineBottomAtom={animateTimelineBottom}
          initialTimelineLeftAtom={initialTimelineLeft}
          animateTimelineLeftAtom={animateTimelineLeft}
          initialTimelineOpacityAtom={initialTimelineOpacity}
          animateTimelineOpacityAtom={animateTimelineOpacity}
          initialTimelineScaleAtom={initialTimelineScale}
          animateTimelineScaleAtom={animateTimelineScale}
          initialAboutBottomAtom={initialAboutBottom}
          animateAboutBottomAtom={animateAboutBottom}
          initialAboutLeftAtom={initialAboutLeft}
          animateAboutLeftAtom={animateAboutLeft}
          initialAboutOpacityAtom={initialAboutOpacity}
          animateAboutOpacityAtom={animateAboutOpacity}
          initialAboutScaleAtom={initialAboutScale}
          animateAboutScaleAtom={animateAboutScale}
          initialFoundersBottomAtom={initialFoundersBottom}
          animateFoundersBottomAtom={animateFoundersBottom}
          initialFoundersLeftAtom={initialFoundersLeft}
          animateFoundersLeftAtom={animateFoundersLeft}
          initialFoundersOpacityAtom={initialFoundersOpacity}
          animateFoundersOpacityAtom={animateFoundersOpacity}
          initialFoundersScaleAtom={initialFoundersScale}
          animateFoundersScaleAtom={animateFoundersScale}
          initialVisionBottomAtom={initialVisionBottom}
          animateVisionBottomAtom={animateVisionBottom}
          initialVisionLeftAtom={initialVisionLeft}
          animateVisionLeftAtom={animateVisionLeft}
          initialVisionOpacityAtom={initialVisionOpacity}
          animateVisionOpacityAtom={animateVisionOpacity}
          initialVisionScaleAtom={initialVisionScale}
          animateVisionScaleAtom={animateVisionScale}
          initialTokenomicsBottomAtom={initialTokenomicsBottom}
          animateTokenomicsBottomAtom={animateTokenomicsBottom}
          initialTokenomicsLeftAtom={initialTokenomicsLeft}
          animateTokenomicsLeftAtom={animateTokenomicsLeft}
          initialTokenomicsOpacityAtom={initialTokenomicsOpacity}
          animateTokenomicsOpacityAtom={animateTokenomicsOpacity}
          initialTokenomicsScaleAtom={initialTokenomicsScale}
          animateTokenomicsScaleAtom={animateTokenomicsScale}
          initialTitleTopAtom={initialTitleTop}
          animateTitleTopAtom={animateTitleTop}
          initialTitleLeftAtom={initialTitleLeft}
          animateTitleLeftAtom={animateTitleLeft}
          initialTitleOpacityAtom={initialTitleOpacity}
          animateTitleOpacityAtom={animateTitleOpacity}
          initialTitleScaleAtom={initialTitleScale}
          animateTitleScaleAtom={animateTitleScale}
        />
      </div>
    </div>
  );
};

export default Mint;
