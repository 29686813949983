import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Connect from 'components/Connect';
import MediaQuery from 'react-responsive';
import 'styles/index.css'
import ReactGA from "react-ga4";
import AnimatedRoutes from 'components/AnimatedRoutes';
import Landing from 'Landing';

const App = () => {

  const [hovered, setHovered] = useState(false);
  const [page, setPage] = useState("eHome");
  const toggleHover = (att) => {
    setHovered(!hovered);
    setPage(att);
  }

    ReactGA.initialize("G-KY6X1RHT26");
    ReactGA.send("pageview");

    const location = useLocation();

    const [navbarOpen, setNavbarOpen] = useState(false)

    const isCurrentURL = (url) => {
        return location.pathname.toLowerCase() === url.toLowerCase();
    }

    const handleToggle = () => {
      setNavbarOpen(prev => !prev)
    }

    return (
        <div className={hovered ? page : ''}>
        {
            !isCurrentURL('/') ?
            <div className={`Main wrap ${!isCurrentURL('/home') ? 'Page' : 'Home'}`}>
                <div className='top-connect'>
                    <Connect />
                </div>
                <div className='app-nav flx' style={{ borderBottomColor: '#ffffff' }}>

                    <MediaQuery minWidth={749}>
                        <ul className='navigation flx'>
                            { !isCurrentURL('/home') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eHome")} onMouseLeave={toggleHover} to='/'>Home</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eHome")} onMouseLeave={toggleHover} to='/'><b>Home</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/mint') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eMint")} onMouseLeave={toggleHover} to='/mint'>Mint</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eMint")} onMouseLeave={toggleHover} to='/mint'><b>Mint</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/roadmap') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eRoad")} onMouseLeave={toggleHover} to='/roadmap'>Roadmap</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eRoad")} onMouseLeave={toggleHover} to='/roadmap'><b>Roadmap</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/about') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eAbout")} onMouseLeave={toggleHover} to='/about'>About</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eAbout")} onMouseLeave={toggleHover} to='/about'><b>About</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/founders') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eFounders")} onMouseLeave={toggleHover} to='/founders'>Founders</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eFounders")} onMouseLeave={toggleHover} to='/founders'><b>Founders</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/vision') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eVision")} onMouseLeave={toggleHover} to='/vision'>Vision</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eVision")} onMouseLeave={toggleHover} to='/vision'><b>Vision</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/tokenomics') ?
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eTokenomics")} onMouseLeave={toggleHover} to='/tokenomics'>Tokenomics</Link></li>
                                </>
                            :
                                <>
                                    <li><Link  className={hovered ? 'link pulse' : 'link'} onMouseEnter={()=>toggleHover("eTokenomics")} onMouseLeave={toggleHover} to='/tokenomics'><b>Tokenomics</b></Link></li>
                                </>
                            }
                            { !isCurrentURL('/buddhaboys') ?
                                <>
                                    <li><Link className='link' to='/buddhaboys'>GALLERY</Link></li>
                                </>
                            :
                                <>
                                    <li><Link className='link' to='/buddhaboys'><b>GALLERY</b></Link></li>
                                </>
                            }
                        </ul>

                    </MediaQuery>

                    <MediaQuery maxWidth={749}>

                            { !isCurrentURL('/home') ?
                                <div className='MobileNav'>

                                    <div className='header__btn'>
                                        <div id='navButton' onClick={handleToggle} className={` ${navbarOpen ? 'open' : ''}`}>
                                            <div className='bars link'><span></span><span></span><span></span><div className='other-bar'></div></div>
                                        </div>
                                    </div>

                                    <div id='mobileMenu' className={`header__menu glass p0 ${navbarOpen ? '' : 'slide'}`}>
                                        <ul>
                                            <li><Link onClick={handleToggle} className='link' to='/'>Home</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/mint'>Mint</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/about'>About</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/roadmap'>Roadmap</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/founders'>Founders</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/vision'>Vision</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/tokenomics'>Tokenomics</Link></li>
                                            <li><Link onClick={handleToggle} className='link' to='/buddhaboys'>Gallery</Link></li>
                                        </ul>
                                    </div>

                                    <h1 style={{ color: '#ffffff!important' }}>MCAA</h1>
                                    <div></div>
                                </div>
                            :
                                <>

                                </>
                            }

                    </MediaQuery>
                    </div>
                    <AnimatedRoutes />
            </div>
            :
            <Landing />
        }
        </div>
    );
};

export default App