import React, { useRef, useEffect } from 'react';

function ThirdFiles() {

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    return (
        <div class="">
            <img className='' src="/community-token.gif" height='400' alt='075' style={{maxWidth: '300px'}} />
        </div>
    );
}

export default ThirdFiles;