import { motion } from 'framer-motion';
import { atom } from "jotai";

export const initialMainBottomAtom = atom("-0vh");
export const animateMainBottomAtom = atom("-0vh");
export const initialMainLeftAtom = atom("-0vw");
export const animateMainLeftAtom = atom("-0vw");
export const initialMainOpacityAtom = atom(0);
export const animateMainOpacityAtom = atom(0);
export const initialMainScaleAtom = atom("scale(1) rotateZ(0deg)");
export const animateMainScaleAtom = atom("scale(1) rotateZ(0deg)");
export const initialMintBottomAtom = atom("-0vh");
export const animateMintBottomAtom = atom("-0vh");
export const initialMintLeftAtom = atom("-0vw");
export const animateMintLeftAtom = atom("-0vw");
export const initialMintOpacityAtom = atom(0);
export const animateMintOpacityAtom = atom(0);
export const initialMintScaleAtom = atom("scale(1)");
export const animateMintScaleAtom = atom("scale(1)");
export const initialTimelineBottomAtom = atom("-0vh");
export const animateTimelineBottomAtom = atom("-0vh");
export const initialTimelineLeftAtom = atom("-0vw");
export const animateTimelineLeftAtom = atom("-0vw");
export const initialTimelineOpacityAtom = atom(0);
export const animateTimelineOpacityAtom = atom(0);
export const initialTimelineScaleAtom = atom("scale(1)");
export const animateTimelineScaleAtom = atom("scale(1)");
export const initialAboutBottomAtom = atom("-0vh");
export const animateAboutBottomAtom = atom("-0vh");
export const initialAboutLeftAtom = atom("-0vw");
export const animateAboutLeftAtom = atom("-0vw");
export const initialAboutOpacityAtom = atom(0);
export const animateAboutOpacityAtom = atom(0);
export const initialAboutScaleAtom = atom("scale(1)");
export const animateAboutScaleAtom = atom("scale(1)");
export const initialFoundersBottomAtom = atom("-0vh");
export const animateFoundersBottomAtom = atom("-0vh");
export const initialFoundersLeftAtom = atom("-0vw");
export const animateFoundersLeftAtom = atom("-0vw");
export const initialFoundersOpacityAtom = atom(0);
export const animateFoundersOpacityAtom = atom(0);
export const initialFoundersScaleAtom = atom("scale(1)");
export const animateFoundersScaleAtom = atom("scale(1)");
export const initialVisionBottomAtom = atom("-0vh");
export const animateVisionBottomAtom = atom("-0vh");
export const initialVisionLeftAtom = atom("-0vw");
export const animateVisionLeftAtom = atom("-0vw");
export const initialVisionOpacityAtom = atom(0);
export const animateVisionOpacityAtom = atom(0);
export const initialVisionScaleAtom = atom("scale(1)");
export const animateVisionScaleAtom = atom("scale(1)");
export const initialTokenomicsBottomAtom = atom("-0vh");
export const animateTokenomicsBottomAtom = atom("-0vh");
export const initialTokenomicsLeftAtom = atom("-0vw");
export const animateTokenomicsLeftAtom = atom("-0vw");
export const initialTokenomicsOpacityAtom = atom(0);
export const animateTokenomicsOpacityAtom = atom(0);
export const initialTokenomicsScaleAtom = atom("scale(1)");
export const animateTokenomicsScaleAtom = atom("scale(1)");

export const initialMapBottomAtom = atom("-0vh");
export const animateMapBottomAtom = atom("-0vh");
export const initialMapLeftAtom = atom("-0vw");
export const animateMapLeftAtom = atom("-0vw");
export const initialMapOpacityAtom = atom(0);
export const animateMapOpacityAtom = atom(0);
export const initialMapScaleAtom = atom("scale(1)");
export const animateMapScaleAtom = atom("scale(1.5)");

export const initialTitleTopAtom = atom("0vh");
export const animateTitleTopAtom = atom("0vh");
export const initialTitleLeftAtom = atom("unset");
export const animateTitleLeftAtom = atom("unset");
export const initialTitleOpacityAtom = atom(1);
export const animateTitleOpacityAtom = atom(1);
export const initialTitleScaleAtom = atom("scale(1)");
export const animateTitleScaleAtom = atom("scale(1.5)");

export const BottomBackground = ({ 
    initialMainBottomAtom,
    animateMainBottomAtom,
    initialMainLeftAtom,
    animateMainLeftAtom,
    initialMainOpacityAtom,
    animateMainOpacityAtom,
    initialMainScaleAtom,
    animateMainScaleAtom,

    initialMintBottomAtom,
    animateMintBottomAtom,
    initialMintLeftAtom,
    animateMintLeftAtom,
    initialMintOpacityAtom,
    animateMintOpacityAtom,
    initialMintScaleAtom,
    animateMintScaleAtom,

    initialTimelineBottomAtom,
    animateTimelineBottomAtom,
    initialTimelineLeftAtom,
    animateTimelineLeftAtom,
    initialTimelineOpacityAtom,
    animateTimelineOpacityAtom,
    initialTimelineScaleAtom,
    animateTimelineScaleAtom,

    initialAboutBottomAtom,
    animateAboutBottomAtom,
    initialAboutLeftAtom,
    animateAboutLeftAtom,
    initialAboutOpacityAtom,
    animateAboutOpacityAtom,
    initialAboutScaleAtom,
    animateAboutScaleAtom,

    initialFoundersBottomAtom,
    animateFoundersBottomAtom,
    initialFoundersLeftAtom,
    animateFoundersLeftAtom,
    initialFoundersOpacityAtom,
    animateFoundersOpacityAtom,
    initialFoundersScaleAtom,
    animateFoundersScaleAtom,

    initialVisionBottomAtom,
    animateVisionBottomAtom,
    initialVisionLeftAtom,
    animateVisionLeftAtom,
    initialVisionOpacityAtom,
    animateVisionOpacityAtom,
    initialVisionScaleAtom,
    animateVisionScaleAtom,

    initialTokenomicsBottomAtom,
    animateTokenomicsBottomAtom,
    initialTokenomicsLeftAtom,
    animateTokenomicsLeftAtom,
    initialTokenomicsOpacityAtom,
    animateTokenomicsOpacityAtom,
    initialTokenomicsScaleAtom,
    animateTokenomicsScaleAtom,

    initialMapBottomAtom,
    animateMapBottomAtom,
    initialMapLeftAtom,
    animateMapLeftAtom,
    initialMapOpacityAtom,
    animateMapOpacityAtom,
    initialMapScaleAtom,
    animateMapScaleAtom,

    initialTitleTopAtom,
    animateTitleTopAtom,
    initialTitleLeftAtom,
    animateTitleLeftAtom,
    initialTitleOpacityAtom,
    animateTitleOpacityAtom,
    initialTitleScaleAtom,
    animateTitleScaleAtom
}) => {

    return (

        <div className="Bottom">
            
            <motion.div className='BGAlt' initial={{ top: initialTitleTopAtom, opacity: initialTitleOpacityAtom, transform: initialTitleScaleAtom }} animate={{ top: animateTitleTopAtom, opacity: animateTitleOpacityAtom, transform: animateTitleScaleAtom }} transition={{ type: 'tween', duration: 1 }}>
                <h1 className='title'>MCAA</h1>
            </motion.div>

            <motion.div className="BG main"
                initial={{ bottom: initialMainBottomAtom, left: initialMainLeftAtom, opacity: initialMainOpacityAtom, transform: initialMainScaleAtom }}
                animate={{ bottom: animateMainBottomAtom, left: animateMainLeftAtom, opacity: animateMainOpacityAtom, transform: animateMainScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                {/*<img src="/home.png" alt="Home" className="Sc-Media Sc-Main" priority="true" />*/}

                {/* <img src="/main-bg.jpg" alt="Home" className="Sc-Media Sc-Main" priority="true"  /> */}

            </motion.div>

            <motion.div className="BG mint"
                initial={{ bottom: initialMintBottomAtom, left: initialMintLeftAtom, opacity: initialMintOpacityAtom, transform: initialMintScaleAtom }}
                animate={{ bottom: animateMintBottomAtom, left: animateMintLeftAtom, opacity: animateMintOpacityAtom, transform: animateMintScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                {/*<img src="/flag.png" alt="Mint" className="Sc-Media Sc-Mint" priority="true" />*/}
            </motion.div>
        
            <motion.div className="BG timeline"
                initial={{ bottom: initialTimelineBottomAtom, left: initialTimelineLeftAtom, opacity: initialTimelineOpacityAtom, transform: initialTimelineScaleAtom }}
                animate={{ bottom: animateTimelineBottomAtom, left: animateTimelineLeftAtom, opacity: animateTimelineOpacityAtom, transform: animateTimelineScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                <img src="scene-timeline.png" alt="Timeline" className="Sc-Media Sc-Timeline" priority="true" />
            </motion.div>

            <motion.div className="BG about"
                initial={{ bottom: initialAboutBottomAtom, left: initialAboutLeftAtom, opacity: initialAboutOpacityAtom, transform: initialAboutScaleAtom }}
                animate={{ bottom: animateAboutBottomAtom, left: animateAboutLeftAtom, opacity: animateAboutOpacityAtom, transform: animateAboutScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                <img src="/scene-vision.png" alt="About" className="Sc-Media Sc-About" priority="true" />
            </motion.div>
            
            <motion.div className="BG founders"
                initial={{ bottom: initialFoundersBottomAtom, left: initialFoundersLeftAtom, opacity: initialFoundersOpacityAtom, transform: initialFoundersScaleAtom }}
                animate={{ bottom: animateFoundersBottomAtom, left: animateFoundersLeftAtom, opacity: animateFoundersOpacityAtom, transform: animateFoundersScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                <img src="scene-founders.png" alt="Founders" className="Sc-Media Sc-Founders" priority="true" />
            </motion.div>

            <motion.div className="BG vision"
                initial={{ bottom: initialVisionBottomAtom, left: initialVisionLeftAtom, opacity: initialVisionOpacityAtom, transform: initialVisionScaleAtom }}
                animate={{ bottom: animateVisionBottomAtom, left: animateVisionLeftAtom, opacity: animateVisionOpacityAtom, transform: animateVisionScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                {/*<img src="/scene-about.png" alt="Vision" className="Sc-Media Sc-Vision" priority="true" />*/}
                <img src="/home.png" alt="Home" className="Sc-Media Sc-Vision" priority="true" />
            </motion.div>
            
            <motion.div className="BG tokenomics"
                initial={{ bottom: initialTokenomicsBottomAtom, left: initialTokenomicsLeftAtom, opacity: initialTokenomicsOpacityAtom, transform: initialTokenomicsScaleAtom }}
                animate={{ bottom: animateTokenomicsBottomAtom, left: animateTokenomicsLeftAtom, opacity: animateTokenomicsOpacityAtom, transform: animateTokenomicsScaleAtom }}
                transition={{ type: "tween", duration: 1 }}
            >
                <img src="scene-utility.png" alt="Tokenomics" className="Sc-Media Sc-Tokenomics" priority="true" />
            </motion.div>
            
        </div>
    );
};
