import React from "react";

function SecondFiles() {
  return (
    <div class="gallery">
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmYrioP4j1g7WGFw5XRPDXvpPL915CohXfi2rA83A5p5Yh"
        width="500"
        height="500"
        alt="075"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmcPAbrGdepLvahd96vVhNmZtYFtYo8j6RPQR9LMKSh3Ej"
        width="500"
        height="500"
        alt="028"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmcSZk6ZxVKEeUs5QsuZDn5RMXdtKE3mFyAadtEBeqnapa"
        width="500"
        height="500"
        alt="036"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmT1uyRikwiBqhN7uB9TSDdYUQzFis4UxZ1pZb6tt8mwxG"
        width="500"
        height="500"
        alt="004"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmZridv9ebAD9WtWkdPeV7Uf2jfUmS1sEH17nubNpausEs"
        width="500"
        height="500"
        alt="011"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmcZ73ix6TkzUEiKhktD6kcJHotWp1sQQNRmq9CphQQiKB"
        width="500"
        height="500"
        alt="015"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmbzFpkzrNNUraPBf8htTpzhEUNMAAdK8vnVPyanaXu3mq"
        width="500"
        height="500"
        alt="041"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmQVQZzWmN2agXXJensAu4k5nJGXuWJzFT4TABeQyAUoTQ"
        width="500"
        height="500"
        alt="072"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmVuQvJbKqd26oGsxaG3dd8X4Q3hJZJ2pLe27AAeLkxAej"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmUNNqCPKdUhvwgFscH4U5KEy1tMqFReQy3xDRm6mFEfzw"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmXs3v5fKPBvyGrtTqSXvB2pC8Cyic2SCs6C4Jic4nqe56"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmdZbjr68CkztPgteiJkEnMxHRpD6v6CVvC16HU8MotTvU"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmeRQaprgveABLYwcv1etYgGTqMrYqpZddjQjb78Y3XHpe"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmS4gUoUDMn84EFFimtkRRf7Y7PppJ12PoKB3ZgMzXrd6Q"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmVZHmywmUniShaj1diySkho1j38wXwh6U4tij9A9Y5qfq"
        width="500"
        height="500"
        alt="076"
      />
      <img
        className="responsive"
        src="https://ipfs.io/ipfs/QmPz6FyevyXRaY1yYFTeroqpKxM5AhEL91CXWKsHybQjUa"
        width="500"
        height="500"
        alt="076"
      />
    </div>
  );
}

export default SecondFiles;
